export const INCIDENT_OVERVIEW = [
	{
		productType: 'KIOSK',
		threat: 143,
		sensitiveData: 120,
		suspicious: 120,
		potentiallyVulnerable: 234,
		others: 0,
	},
	{
		productType: 'UF3',
		threat: 143,
		sensitiveData: 120,
		suspicious: 120,
		potentiallyVulnerable: 234,
		others: 0,
	},
	{
		productType: 'MK5',
		threat: 143,
		sensitiveData: 120,
		suspicious: 120,
		potentiallyVulnerable: 234,
		others: 0,
	},
];

export const INCIDENT_DETAIL = {
	total: 100,
	page: 0,
	totalPages: 1,
	rows: [
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
		{
			productType: 'KIOSK',
			filePath: '/disk1/file1',
			malware: 'Ransomware',
			instanceName: 'Kiosk 1',
			user: 'Guest',
			startTime: 1705308504,
			mediaType: 'USB-A',
			issue: 'SUSPICIOUS',
		},
	],
};
