import { ApexGrid } from 'ng-apexcharts';

export const chartSeries = {
	Threats: 'Threats',
	Media: 'Media',
	Files: 'Files',
	Sanitized: 'Sanitized',
	MediaWithThreats: 'Media w/ Threats',
	AverageTime: 'Average Time per Session (min)',
	AverageFiles: 'Average Files per Session',
};

export const gridChart: ApexGrid = {
	show: true,
	borderColor: '#EEEFF0',
	strokeDashArray: 0,
	position: 'back',
	xaxis: {
		lines: {
			show: true,
		},
	},
	yaxis: {
		lines: {
			show: true,
		},
	},
};
