export const IS_NEW = {
	general: { newNotification: true },
	security_incident: { hasExisted: true, newNotification: true },
};

export const NOTIFICATION_LIST = [
	{
		id: '657c0c06ad05da74b68fc2eef',
		accountId: 'faminodxqa',
		userId: '72828409-9173-4a9b-b56c-fadd0203b9ac',
		type: 'instance_enrollment',
		summary: 'There are 3 MetaDefender Kiosk K-Series instances enrolled.',
		createdAt: '1702868859964',
		severity: 'information',
		read: false,
	},
	{
		id: '657c0bb8ad05da74b68c2ee9',
		accountId: 'faminodxqa',
		userId: '72828409-9173-4a9b-b56c-fadd0203b9ac',
		type: 'instance_enrollment',
		summary: 'There is 1 MetaDefender Kiosk L-Series instance enrolled.',
		createdAt: '1702628280155',
		severity: 'information',
		read: false,
	},
	{
		id: '657c0c06ad05da74b68c2eef',
		accountId: 'faminodxqa',
		userId: '72828409-9173-4a9b-b56c-fadd0203b9ac',
		type: 'security_incident',
		summary: null,
		createdAt: '1702868859964',
		severity: 'critical',
		read: false,
		info: {
			productType: 'KIOSK',
			groupName: 'Kiosk HCM',
			instanceName: 'Kiosk 1',
			issue: 'THREAT',
			fileFath: '/home/xphatng/text',
			user: 'xphatng',
			startTime: '',
			mediaType: '',
		},
	},
	{
		id: '657c0bb8ad05da74b68c2eef9',
		accountId: 'faminodxqa',
		userId: '72828409-9173-4a9b-b56c-fadd0203b9ac',
		type: 'security_incident',
		summary: null,
		createdAt: '1702628280155',
		severity: 'critical',
		read: false,
		info: {
			productType: 'KIOSK',
			groupName: 'Kiosk HCM',
			instanceName: 'Kiosk 1 Ho Chi Minh City Entrance B1',
			issue: 'THREAT',
			fileFath: '/home/xphatng/text',
			user: 'xphatng',
			startTime: '',
			mediaType: '',
		},
	},
];
