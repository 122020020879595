import {
	defaultKioskModules,
	defaultMediaFirewallModules,
} from '@app/views/content-view/modules/inventory/metadefender-kiosk/overview/dashboard/constants';
import { OPSWAT_PRODUCTS, OPTION, PERMISSION, URL_CONSTANT } from '../constants';
import { IDropdownOption } from '../models';
import { Message } from './../models/toast.model';

export function toggleInstanceAction(options: IDropdownOption[], selectedItems: any, permission: any = null) {
	options.forEach((option) => {
		if (option && option.disabled !== undefined) {
			if (option.value === OPTION.DELETE.value && permission) {
				option.disabled = !selectedItems.length || permission === PERMISSION.VIEW_ONLY;
			} else {
				option.disabled = !selectedItems.length;
			}
		}
	});
}

export function toggleGroupDetailAction(options: IDropdownOption[], selectedItems: any, hasConfig: boolean, permission: any) {
	options.forEach((option) => {
		if (option && option.disabled !== undefined) {
			if (option.value === 'applyGroupConfiguration') {
				option.disabled = !selectedItems.length || !hasConfig || permission === PERMISSION.VIEW_ONLY;
			} else {
				option.disabled = !selectedItems.length || permission === PERMISSION.VIEW_ONLY;
			}
		}
	});
}

export function showSuccessRenameMessage(name: string): Message {
	return { severity: 'positive', life: 5000, summary: `Renamed ${name}(s)`, detail: 'Successfully' };
}

export function showFailedRenameMessage(name: string): Message {
	return {
		severity: 'negative',
		life: 5000,
		summary: `Renamed ${name}(s) Failed`,
		detail: `Unable to change names. This might be due to duplicated ${name.toLowerCase()} name, deleted ${name.toLowerCase()}, or unauthorized reason.`,
	};
}

export function showPartialRenameMessage(name: string): Message {
	return {
		severity: 'cautionary',
		life: 5000,
		summary: `Renamed ${name}(s) Partially Successful`,
		detail: `Some names were changed and some were not. This might be due to duplicated ${name.toLowerCase()} name, deleted ${name.toLowerCase()}, or unauthorized reason.`,
	};
}

export function clearLocalStorage(excludeKeys: any) {
	const removeKeys = [];
	for (let i = 0; i < localStorage.length; i++) {
		const key = localStorage.key(i);

		if (excludeKeys.indexOf(key) === -1) {
			if (key) {
				removeKeys.push(key);
			}
		}
	}
	removeKeys.forEach((key) => {
		localStorage.removeItem(key);
	});
}

export function getInstanceUrl(productType: string) {
	return productType === OPSWAT_PRODUCTS.MK5.TYPE ? URL_CONSTANT.INVENTORY.MK5.INSTANCE_LIST : URL_CONSTANT.INVENTORY.KIOSK.INSTANCE_LIST;
}

export function getProductName(productType: string, productTypes: string[]) {
	return productType === OPSWAT_PRODUCTS.MK5.TYPE ? OPSWAT_PRODUCTS.MK5.FULL_NAME : OPSWAT_PRODUCTS.KIOSK.FULL_NAME;
}

export function getDefaultKioskModules() {
	return defaultKioskModules.map((obj: any) => ({ ...obj }));
}

export function getDefaultMediaFirewallModules() {
	return defaultMediaFirewallModules.map((obj: any) => ({ ...obj }));
}

export function buildDataForSavingCustomDashboard(data: any, defaultData: any) {
	const newData = defaultData;
	let matchingMockDataItem: any = [];
	data.forEach((checkedCategory: any) => {
		const { categoryKey, children } = checkedCategory;

		children.forEach((child: any) => {
			const { key, checked } = child;
			matchingMockDataItem = newData.find((item: any) => item.categoryKey === categoryKey && item.key === key);

			if (matchingMockDataItem) {
				matchingMockDataItem.isDisplay = checked;
			}

			if (matchingMockDataItem.isDisplay) {
				matchingMockDataItem.x = child.x;
				matchingMockDataItem.y = child.y;
				matchingMockDataItem.w = child.w;
				matchingMockDataItem.h = child.h;
			} else {
				matchingMockDataItem.x = 0;
				matchingMockDataItem.y = 0;
				matchingMockDataItem.w = 0;
				matchingMockDataItem.h = 0;
			}
		});
	});
	return newData;
}

export function buildDataForSavingCustomDashboardFirewall(data: any, serializedData: any) {
	const flattenedData = data.flatMap((category: any) => {
		const { categoryKey, categoryName } = category;
		return category.children.map(({ checked, thumbnail, ...rest }: any) => ({
			...rest,
			isDisplay: checked,
			categoryKey,
			categoryName,
		}));
	});

	const newArray = serializedData.children
		.map((child: any) => {
			const matchingElement = flattenedData.find((element: any) => element.key === child.id);

			if (matchingElement) {
				return {
					...matchingElement,
					x: child.x,
					y: child.y,
					w: child.w,
					h: child.h,
				};
			}

			return null;
		})
		.filter(Boolean);

	flattenedData.forEach((element: any) => {
		const isElementInB = serializedData.children.some((child: any) => child.id === element.key);
		if (!isElementInB) {
			newArray.push(element);
		}
	});

	return newArray;
}

export function getDashboardThumbnail(item: string) {
	const barChart = ['kioskHealth', 'kioskLastSeen', 'kioskVersion', 'freeSpace', 'topFileTypes', 'blocked', 'sessionsPerMediaType'];
	const pieChart = ['licenseStatus', 'verdicts'];
	const lineGraph = ['processingResult', 'mediaSessions', 'averageFilesAndTimePerSession', 'scanResults', 'totalSessions'];
	const summary6 = ['dataSummary'];
	const summary2 = [''];
	const summary4 = ['status'];
	const summary3 = ['topGroupBasedThreats'];

	if (barChart.includes(item)) {
		return 'assets/images/bar-chart.svg';
	} else if (pieChart.includes(item)) {
		return 'assets/images/pie-chart.svg';
	} else if (lineGraph.includes(item)) {
		return 'assets/images/line-graph.svg';
	} else if (summary6.includes(item)) {
		return 'assets/images/data-summary-6.svg';
	} else if (summary2.includes(item)) {
		return 'assets/images/data-summary-2.svg';
	} else if (summary4.includes(item)) {
		return 'assets/images/data-summary-4.svg';
	} else if (summary3.includes(item)) {
		return 'assets/images/data-summary-3.svg';
	}

	return '';
}
