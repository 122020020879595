import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import * as dayjstimezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';

dayjs.extend(dayjstimezone);
dayjs.extend(utc);

@Pipe({
	name: 'timezoneDate',
})
export class TimezonePipe implements PipeTransform {
	transform(value: any, format: string, timezone: string = localStorage.getItem('timezone') || ''): any {
		if (value) {
			if (timezone) {
				if (typeof value === 'string') {
					const numberValue = parseFloat(value);
					return dayjs(numberValue).tz(timezone).format(format);
				}
				return dayjs(value).tz(timezone).format(format);
			} else {
				const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				if (typeof value === 'string') {
					const numberValue = parseFloat(value);
					return dayjs(numberValue).tz(browserTimezone).format(format);
				}
				return dayjs(value).tz(browserTimezone).format(format);
			}
		}
		return '-';
	}
}
