import { OPSWAT_PRODUCTS } from "@app/shared/constants";

//SQIgnore
export const MOCK = [
	{
		productType: 'OTHER',
		productName: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
		totalInstances: 0,
		active: 121,
		activeLicense: true,
		imageUrl: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/product/metadefender-drive.png',
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\"> versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
	{
		productType: 'UF3',
		productName: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
		totalInstances: 1,
		active: 121,
		activeLicense: true,
		imageUrl: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/product/metadefender-drive.png',
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\">The OPSWAT Endpoint Security SDK is a cross-platform, versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
	{
		productType: 'MDCORE',
		productName: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
		totalInstances: 1,
		active: 121,
		activeLicense: true,
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\">The OPSWAT Endpoint Security SDK is a cross-platform, versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
	{
		productType: 'OTFUSE',
		productName: 'OTFUSE',
		totalInstances: 1,
		active: 121,
		activeLicense: true,
		imageUrl: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/product/metadefender-drive.png',
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\">The OPSWAT Endpoint Security SDK is a cross-platform, versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
	{
		productType: 'UF3',
		productName: OPSWAT_PRODUCTS.MDCORE.FULL_NAME,
		totalInstances: 0,
		active: 0,
		activeLicense: true,
		imageUrl: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/product/metadefender-drive.png',
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\">The OPSWAT Endpoint Security SDK is a cross-platform, versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '2.4.1-1',
				total: 2,
				status: 'MAJOR BEHIND',
			},
			{
				value: '5.0.0',
				total: 119,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '5.4.0',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '5.4.1-1',
				total: 3,
				status: 'LATEST',
			},
			{
				value: '5.4.1-0',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
	{
		productType: 'MDEMAIL',
		productName: 'MDEMAIL',
		totalInstances: 0,
		active: 121,
		activeLicense: false,
		imageUrl: 'https://myopswat-staging.s3.us-east-2.amazonaws.com/images/product/metadefender-drive.png',
		description:
			'<div><strong>Overview</strong> <p class=\\"m-t-sm m-b-none\\">The OPSWAT Endpoint Security SDK is a cross-platform, versatile and modular framework that enables software engineers and technology vendors to build advanced endpoint security products.</p> </div>',
		slug: 'metadefender-cloud-email-security',
		health: {
			operational: 15,
			minorIssues: 1,
			criticalIssues: 104,
			unknown: 8,
			significantIssues: 0,
		},
		version: [
			{
				value: '6.4.1-1',
				total: 1,
				status: 'LATEST',
			},
			{
				value: '5',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '2.4.1-1',
				total: 2,
				status: 'MAJOR BEHIND',
			},
			{
				value: '5.0.0',
				total: 119,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '5.4.0',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
			{
				value: '5.4.1-1',
				total: 3,
				status: 'LATEST',
			},
			{
				value: '5.4.1-0',
				total: 1,
				status: 'SLIGHT BEHIND',
			},
		],
		licenses: {
			active: 124,
			expire30Days: 0,
			expire90Days: 0,
			expire90PlusDays: 124,
			expired: 0,
			noLicense: 4,
		},
	},
];
