import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';

@Pipe({
	name: 'toggleTooltipBaseOnWidthText',
	pure: true,
})
export class ToggleTooltipBaseOnWidthTextPipe implements PipeTransform {
	constructor(private readonly cdr: ChangeDetectorRef) { }

	transform(toolTip: any, args?: any): any {
		return this.toggleTooltip(toolTip);
	}

	toggleTooltip(toolTip: any) {
		this.cdr.detectChanges();
		return toolTip.scrollWidth <= toolTip.clientWidth;
	}
}
