import { Injectable, signal } from '@angular/core';
import { forkJoin, interval, of, Subscription } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { UpgradeOvaService } from '../upgrade-ova/upgrade-ova.service';
import { Router } from '@angular/router';
import { URL_CONSTANT } from '@app/shared/constants';
import { environment } from '@env/environment';

@Injectable({
	providedIn: 'root',
})
export class SystemAlertPollingService {
	private subscription: Subscription | null = null;

	ONE_MINUTE_IN_SECOND = 60;
	ONE_MINUTE_IN_MILI_SECOND = 60000;

	isShow = signal(false);
	type = signal('upgraded');

	constructor(private readonly upgradeOvaService: UpgradeOvaService, private readonly router: Router) {}

	startPolling(): void {
		if (environment.onCloud) {
			return;
		}

		if (this.subscription) {
			return;
		}

		this.subscription = interval(this.ONE_MINUTE_IN_MILI_SECOND)
			.pipe(
				switchMap(() =>
					forkJoin({
						upgradeData: this.upgradeOvaService.checkSystemIsUpgrading().pipe(
							catchError((error) => {
								console.error('Failed to get Data 1:', error);
								return of({ error: true, message: 'Error in upgradeData' });
							})
						),
						importData: this.upgradeOvaService.checkImportingFile().pipe(
							catchError((error) => {
								console.error('Failed to get Data 2:', error);
								return of({ error: true, message: 'Error in importData' });
							})
						),
						diskData: this.upgradeOvaService.checkStorageSpace().pipe(
							catchError((error) => {
								console.error('Failed to get Data 3:', error);
								return of({ error: true, message: 'Error in diskData' });
							})
						),
					})
				)
			)
			.subscribe({
				next: (response) => {
					this.handleDiskDataResponse(response?.diskData);
					this.handleImportDataResponse(response?.importData);
					this.handleUpgradeDataResponse(response?.upgradeData);
				},
				error: (err) => {
					console.error('Error in polling:', err);
				},
			});
	}

	stopPolling(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = null;
		}
	}

	handleUpgradeDataResponse(response: any) {
		if (response?.result) {
			this.isShow.set(true);
			this.type.set('upgraded');
		}

		if (response?.result === true && response?.time_left >= 0) {
			if (response.time_left <= this.ONE_MINUTE_IN_SECOND) {
				this.router.navigate([`${URL_CONSTANT.SYSTEM_UPGRADING}`]);
			}
		}
	}

	handleImportDataResponse(response: any) {
		if (response?.time_left) {
			this.isShow.set(true);
			this.type.set('restored');

			if (response?.time_left <= this.ONE_MINUTE_IN_SECOND) {
				this.router.navigate([`${URL_CONSTANT.SYSTEM_IMPORTING_FILE}`]);
			}
		}
	}

	handleDiskDataResponse(response: any) {
		if (response?.disk && response?.disk?.used && response?.disk?.total) {
			const usedPercent = Math.ceil(
				(parseInt(response.disk.used.toString()) / parseInt(response.disk.total.toString())) * 100
			).toString();

			if (usedPercent && parseInt(usedPercent) >= 70) {
				this.isShow.set(true);
				this.type.set('disk');
			}
		}
	}
}
