<div class="dialog-large__header">
	<h4>Enroll {{ product.FULL_NAME }}</h4>
</div>
<div mat-dialog-content class="dialog-medium__content">
	<span [style.opacity.%]="70" class="text-medium">Provide registration informations for ownership and access to features.</span>

	<div class="m-l-md">
		<p class="text-medium">1. Copy Configuration details</p>
		<p class="color-primary text-medium m-l-md">Registration Code</p>
		<ng-container *ngIf="!isLoading; else isloading">
			<p class="text-medium flex flex-middle m-l-md">
				{{ regCode }}
				<mat-icon svgIcon="clone" class="m-l-sm cursor-pointer" (click)="utilService.copyToClipBoard(regCode)"></mat-icon>
			</p>
		</ng-container>

		<p class="color-primary text-medium m-l-md">My OPSWAT URL</p>
		<p class="text-medium flex flex-middle m-l-md">
			{{ OCMURL }}<mat-icon svgIcon="clone" class="m-l-sm cursor-pointer" (click)="utilService.copyToClipBoard(OCMURL)"></mat-icon>
		</p>
		<p class="text-medium">2. Enter both codes into the Product to enroll the product</p>
		<p class="text-medium">3. Return to My OPSWAT and refresh to complete enrollment.</p>
	</div>

	<span [style.opacity.%]="70" class="text-medium">
		Additional information including a detailed step-by-step guide is available in Product Documentation (online resource).
	</span>
</div>

<div class="dialog-large__footer">
	<button [style.width.%]="100" mat-button color="primary" (click)="goToGuideDoc()">{{ product.SHORT_NAME }} Documentation</button>
</div>

<ng-template #isloading>
	<div class="m-l-md">
		<app-loading-error-indicator [nrRows]="1"></app-loading-error-indicator>
	</div>
</ng-template>
