import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { URL_CONSTANT } from '@app/shared/constants';
import { AuthService } from '@app/shared/services';
import { CmJwtService } from '@app/shared/services/jwt/cm-jwtToken.service';

@Component({
	selector: 'app-expired-session-dialog',
	templateUrl: './expired-session-dialog.component.html',
})
export class ExpiredSessionDialogComponent {
	failedToRefresh = false;
	interval: any = null;
	timeLeft = 5;

	constructor(
		public dialogRef: MatDialogRef<ExpiredSessionDialogComponent>,
		private readonly router: Router,
		private readonly authService: AuthService,
		private readonly cmJwtService: CmJwtService
	) { }

	onExtendSession() {
		this.authService.refreshToken().subscribe(
			(res: any) => {
				if (typeof res.token !== 'string') {
					throw new Error('Token not found in the response');
				}
				this.cmJwtService.saveToken(res.token, 'session-extend-click');
				this.dialogRef.close();
			},
			(error: any) => {
				/**
				 * If token fails to refresh for whatever reason,
				 * we show a message to the user and redirect
				 * to a login page in <timeLeft> seconds
				 */
				this.failedToRefresh = true;
				clearInterval(this.interval);
				this.interval = setInterval(() => {
					this.countdown();
				}, 1000);
				this.dialogRef.close();
			}
		);
	}

	countdown() {
		this.timeLeft -= 1;
		if (this.timeLeft <= 0) {
			clearInterval(this.interval);
			this.router.navigate([URL_CONSTANT.REDIRECT]);
		}
	}

	logOut() {
		this.router.navigate([URL_CONSTANT.REDIRECT]);
		this.dialogRef.close();
	}
}
