export const TITLE_POSTFIX = ' - My OPSWAT';
export const TITLE_MY_PRODUCTS = ' - My Products';

export const PAGE_TITLE = {
	DASHBOARD: {
		METADEFENDER_KIOSK_WINDOW: 'MetaDefender Kiosk K Series' + TITLE_MY_PRODUCTS + TITLE_POSTFIX,
	},
	METADEFENDER_KIOSK: 'MetaDefender Kiosk' + TITLE_MY_PRODUCTS + TITLE_POSTFIX,
	INVENTORY: 'My Products' + TITLE_POSTFIX,
	EVENT_AND_HISTORY: 'Events History' + TITLE_POSTFIX,
	USER_MANAGEMENT: 'User Permission' + TITLE_POSTFIX,
	USER_PROFILE: 'User Profile' + TITLE_POSTFIX,
	SETTINGS: 'Settings' + TITLE_POSTFIX,
	PAGE_NOT_FOUND: 'Page Not Found',
	PERMISSION_DENIED: 'Permission Denied',
	LICENSES: 'Licenses' + TITLE_POSTFIX,
	SUPPORTS: 'Supports',
	ONPREM_USER_MANAGEMENT: 'User Management' + TITLE_POSTFIX,
	TERMS_AND_POLICY: 'Terms and policy' + TITLE_POSTFIX,
	LOGIN: 'Login' + TITLE_POSTFIX,
	RESET_PASSWORD: 'Reset Password' + TITLE_POSTFIX,
	SERVICE_UNAVAILABLE: 'Service Unavailable' + TITLE_POSTFIX,
	SETUP_WIZARD: 'Setup Wizard' + TITLE_POSTFIX,
};
