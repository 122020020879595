export const CERTS_LIST = {
	total: 2,
	page: 0,
	totalPages: 1,
	lastUpdate: 0,
	rows: [
		{
			id: '657175628789d301e4ce4db3',
			name: 'cert 1',
			description: 'description for cert 1',
			issuedTo: 'Internet Widgits Pty Ltd',
			issuedBy: 'Internet Widgits Pty Ltd',
			validFrom: 1654550199000,
			validTo: 1969910199000,
			active: true,
		},
		{
			id: '6571762e8789d301e4ce4db4',
			name: 'cert 2',
			description: 'description for cert 2',
			issuedTo: 'Internet Widgits Pty Ltd',
			issuedBy: 'Internet Widgits Pty Ltd',
			validFrom: 1654550199000,
			validTo: 1969910199000,
			active: true,
		},
	],
};
export const CERT_DETAIL = {
	expiration: 2004427631000,
	info: 'SERIALNUMBER=c2425568, OID.2.5.4.15=US, CN=ocmubuntu, O=OPSWAT, OU=OPSWAT Central Management, L=San Francisco, C=US',
};

export const CERT_ERROR = {
	error: 'Could not load certificate',
};
