export enum PERMISSION {
	NONE = 'NONE',
	VIEW_ONLY = 'VIEW_ONLY',
	FULL = 'FULL',
}

export const PERMISSION_KEY = {
	inventory: 'inventory',
	userManagement: 'userManagement',
	eventsHistory: 'eventsHistory',
	settings: 'settings',
	userProfile: 'userProfile',
	licenses: 'licenses',
	global: 'global',
	products: 'products',
	reports: 'reportsNotifications',
	tools: 'tools',
	integrations: 'integrations',
};

export const DEFAULT_INVENTORY_PERMISSION = ['VIEW_ONLY', 'FULL'];
export const PERMISSION_ORDERS = [
	PERMISSION_KEY.inventory,
	PERMISSION_KEY.userManagement,
	PERMISSION_KEY.eventsHistory,
	PERMISSION_KEY.licenses,
	PERMISSION_KEY.settings,
];
export const SETTING_PERMISSION_ORDERS = [
	PERMISSION_KEY.global,
	PERMISSION_KEY.products,
	PERMISSION_KEY.reports,
	PERMISSION_KEY.integrations,
	PERMISSION_KEY.tools,
];
export const PERMISSION_EXCLUDE_KEYS = ['level', 'isCustom'];

export const KEY_OF_GROUP_ID_VALUE = {
	GROUP_ID: 'groupId',
};
