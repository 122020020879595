import { CONSTANT } from '@app/shared/constants';
import { CERTS_LIST, CERT_DETAIL } from './certificates.mock';
import { REPORTS_MOCK, SERVERS_SETTING, SERVERS_SETTING_FRESH } from './servers.mock';

const modulesMock = [
	{
		id: '65fbf293cade38d4543beb7e',
		engineId: 'k7_1_windows',
		module: 'K7',
		source: 'MANUAL',
		type: 'av',
		enabled: true,
		enginePackageSize: 4917519,
		databasePackageSize: 230900733,
		engineReleasedTime: 1711013221414,
		databaseReleasedTime: 1711013221421,
		lastUsed: 1711013221421,
		platform: 'linux',
	},
	{
		id: '65fbf293cade38d4543beb7f',
		engineId: 'k7_1_linux',
		module: 'K7',
		source: 'MANUAL',
		type: 'av',
		enabled: true,
		enginePackageSize: 4917519,
		databasePackageSize: 230900733,
		engineReleasedTime: 1711013221414,
		databaseReleasedTime: 1711013221421,
		lastUsed: 1711013221421,
		platform: 'linux',
	},
];
const contentType = 'multipart/form-data';
export const SETTINGS = {
	activeKioskLicense: {
		url: `${CONSTANT.URL_PREFIX}op/license/activate-offline`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		contentType: contentType,
		headers: {},
	},
	activeOnlineKioskLicense: {
		url: `${CONSTANT.URL_PREFIX}op/license/activate-online`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},

	getLicenseInfo: {
		url: `${CONSTANT.URL_PREFIX}op/license/info`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},

	getAccountSetting: {
		url: `${CONSTANT.URL_PREFIX}account/get-account-settings`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},

	saveAccountSetting: {
		url: `${CONSTANT.URL_PREFIX}account/update-account-settings`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: false,
		headers: {},
	},

	deactiveKioskLicense: {
		url: `${CONSTANT.URL_PREFIX}op/license/deactivate`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
	revokeCode: {
		url: `${CONSTANT.URL_PREFIX}account/registration-code/revoke`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
	getVersion: {
		url: `${CONSTANT.URL_PREFIX}version`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	getAboutOneCentral: {
		url: `${CONSTANT.URL_PREFIX}about`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	getCertificates: {
		url: `${CONSTANT.URL_PREFIX}cert/search`,
		mock: CERTS_LIST,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_TRUST_CERTIFICATES: {
		url: `${CONSTANT.URL_PREFIX}cert/trust/search`,
		mock: CERTS_LIST,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_SSL_VERIFICATION: {
		url: `${CONSTANT.URL_PREFIX}cert/trust/verify`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	createCertificate: {
		url: `${CONSTANT.URL_PREFIX}cert`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		contentType: contentType,
	},
	CREATE_CERTIFICATE_ON_TRUSTED_LIST: {
		url: `${CONSTANT.URL_PREFIX}cert/trust`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		contentType: contentType,
	},

	updateCertificate: {
		url: `${CONSTANT.URL_PREFIX}cert/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	deleteCertificate: {
		url: `${CONSTANT.URL_PREFIX}cert/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
	},
	DELETE_TRUST_CERTIFICATE: {
		url: `${CONSTANT.URL_PREFIX}cert/trust/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
	},
	getCertificateDetails: {
		url: `${CONSTANT.URL_PREFIX}cert/`,
		mock: CERT_DETAIL,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	getServersSetting: {
		url: `${CONSTANT.URL_PREFIX}settings/SERVER`,
		mock: SERVERS_SETTING_FRESH,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	saveServersSetting: {
		url: `${CONSTANT.URL_PREFIX}settings`,
		mock: SERVERS_SETTING,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	getReportsSetting: {
		url: `${CONSTANT.URL_PREFIX}settings/summary-reports`,
		mock: REPORTS_MOCK,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	saveReportsSetting: {
		url: `${CONSTANT.URL_PREFIX}settings/summary-reports`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	SAVE_CERTIFICATE_VERIFICATION: {
		url: `${CONSTANT.URL_PREFIX}cert/trust/verify`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: false,
		headers: {},
	},
	GET_MODULES: {
		url: `${CONSTANT.URL_PREFIX}modules`,
		mock: modulesMock,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
	},
	UPLOAD_MODULES: {
		url: `${CONSTANT.URL_PREFIX}modules`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: false,
		contentType: contentType,
		headers: {},
		observe: 'events',
		reportProgress: true,
	},
	DELETE_MODULE: {
		url: `${CONSTANT.URL_PREFIX}modules`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: false,
	},
	getServerTimezone: {
		url: `${CONSTANT.URL_PREFIX}server-timezone-offset`,
		mock: 'UTC+07:00',
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	SEND_TEST_365_EMAIL: {
		url: `${CONSTANT.URL_PREFIX}settings/verify-can-send-email`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
};
