import { Inject, Injectable } from '@angular/core';
import { CONSTANT, DIALOG_HEIGHT, DIALOG_WIDTH, URL_CONSTANT } from '@app/shared/constants';
import { IGeneralTableCriteria } from '@app/shared/models';
import { environment } from '@env/environment';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PublisherService } from '../publisher/publisher.service';
import { AccountApiService } from './account-api.service';
import { ToastService } from '../toast.service';
import { IAccount, IAccountRowData } from '@app/views/content-view/modules/settings/model';
import { UserProfileService } from '@app/views/content-view/modules/user-profile/services/user-profile.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CmJwtService } from '../jwt/cm-jwtToken.service';
import { APP_BASE_HREF } from '@angular/common';
import { UserRemovedSwitchAccountDialogComponent } from '@app/shared/components/user-removed-switch-account-dialog/user-removed-switch-account.component';

@Injectable({
	providedIn: 'root',
})
export class AccountService {
	inValidAccountIdMsg = 'Invalid account id';

	constructor(
		@Inject(APP_BASE_HREF) private readonly baseHref: string,
		private readonly accountApiService: AccountApiService,
		private readonly publisherService: PublisherService,
		private readonly toastService: ToastService,
		public readonly userProfileService: UserProfileService,
		public readonly dialog: MatDialog,
		private readonly cmJwtService: CmJwtService
	) {}

	init() {
		if (environment.onCloud) {
			this.getAccountInfo();
		} else {
			this.getAccountList();
		}
	}

	getAccountInfo() {
		const searchCriteria: IGeneralTableCriteria = {
			limit: CONSTANT.MAXIMUM_ACCOUNT_RECORD,
			page: 0,
		};

		const accountList$ = this.accountApiService.getAccountList(searchCriteria).pipe(
			catchError((error) => {
				return of(error.error);
			})
		);

		const dxAccounts$ = this.accountApiService.getDxAccountList().pipe(
			catchError(() => {
				return of({ errors: [{ message: 'Failed to get user information. Please try again later.' }] });
			})
		);

		const dxProfile$ = this.accountApiService.getDxProfile().pipe(
			catchError(() => {
				return of({ errors: [{ message: 'Failed to get user information. Please try again later.' }] });
			})
		);

		forkJoin([accountList$, dxAccounts$, dxProfile$]).subscribe(([accountList, dxAccountList, dxProfile]: any) => {
			const result: any = {};
			result.accountList = accountList;
			result.dxAccountList = dxAccountList;
			result.dxProfile = dxProfile;

			this.publisherService.publish('dxProfile', result.dxProfile);
			const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
			if (dxProfile?.data?.profile) {
				localStorage.setItem('timezone', dxProfile?.data?.profile.timezone || browserTimezone);
			} else {
				const localTimezone = localStorage.getItem('timezone') || browserTimezone;
				localStorage.setItem('timezone', localTimezone);
			}

			this.checkSwitchAcc(result);
		});
	}

	getAccountList() {
		const searchCriteria: IGeneralTableCriteria = {
			limit: CONSTANT.MAXIMUM_ACCOUNT_RECORD,
			page: 0,
		};
		this.accountApiService.getAccountList(searchCriteria).subscribe(
			(accountList: IAccount) => {
				const result: any = {
					dxProfile: {},
					accountList: {},
					dxAccountList: {},
				};

				result.accountList = accountList;
				this.publisherService.publish('accountInfo', { ...result });
			},
			(res: any) => {
				this.toastService.add({
					severity: 'negative',
					summary: res?.error?.error,
				});
			}
		);
	}

	checkSwitchAcc(accountInformation: any) {
		if (accountInformation.dxAccountList?.data && accountInformation.dxProfile?.data) {
			const dxOrganizations = accountInformation.dxAccountList.data.organizations;
			const currentOcmOrg = accountInformation.accountList.rows.find((org: any) => org.currentAccount);
			const currentDxOrgId = accountInformation.dxProfile.data.profile.currentOrganizationId;
			const currentDxOrg = dxOrganizations.find((org: any) => org.id === currentDxOrgId);
			const sourceSwitchAccount = localStorage.getItem('switchOrg');

			if (
				currentDxOrg &&
				currentOcmOrg?.accountId !== currentDxOrg?.ssoId &&
				!currentOcmOrg?.individualOrg &&
				sourceSwitchAccount !== 'switch'
			) {
				this.ocmSwitchAccount({ accountId: currentDxOrg.ssoId }, 'landing');
			}
		}
		this.publisherService.publish('accountInfo', { ...accountInformation });
	}

	ocmSwitchAccount(account: any, source = 'switch') {
		// switch account save token
		this.userProfileService.switchAccount({ accountId: account.accountId }).subscribe(
			(res: any) => {
				if (res) {
					this.cmJwtService.saveToken(res.token, 'switch-account');
					localStorage.setItem('switchOrg', source);
					window.location.href = this.baseHref + URL_CONSTANT.INVENTORY.INVENTORY;
				}
			},
			(error: any) => {
				if (error.error?.error !== this.inValidAccountIdMsg) {
					this.toastService.add({
						severity: 'negative',
						summary: 'Failed to switch organizations. Please re-login and try again!',
					});
				} else {
					this.handleRemoveAccountFromSwitchAccount(account);
				}
			}
		);
	}

	private handleRemoveAccountFromSwitchAccount(account: IAccountRowData) {
		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
			this.dialog.open(UserRemovedSwitchAccountDialogComponent, {
				height: DIALOG_HEIGHT.SMALL,
				width: DIALOG_WIDTH.SMALL,
				disableClose: true,
				data: {
					accountName: account.accountName || account.accountId,
				},
			});
		}
	}
}
