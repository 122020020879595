import { IChartInfo, IFilterOption } from '../model';

export const MAXIMUM_NUMER_DISPLAY = 99999;
export const NUMBER_ELEMENT_GROUP = 3;
export const FREE_SPACE_CELL_HEIGHT = 25;
export const RESOURCE_OVERVIEW_TOOLTIP = {
	OVERVIEW: "This section doesn't count No License and Disconnected instances.",
};
export enum UNIT_FILTER {
	HOUR = 'hour',
	DAY = 'day',
	MONTH = 'month',
	YEAR = 'year',
}
export const DASHBOARD_FILTER = {
	ALLKIOSKS: 'All Kiosks',
	ALLGROUPS: 'All Groups',
};

export const PRODUCT_TYPE_MK5 = 'MK5';
export const PRODUCT_TYPE_KIOSK = 'KIOSK';
export const PRODUCT_TYPE = [PRODUCT_TYPE_MK5, PRODUCT_TYPE_KIOSK];
export const WINDOWS_KIOSK_DOCUMENTATION = 'https://docs.opswat.com/mdkiosk';
export const LINUX_KIOSK_DOCUMENTATION = 'https://docs.opswat.com/mdkiosk-linux';
export const DASHBOARD_COLORS = {
	REDA: '#CB0704',
	REDB: '#D00300',
	REDC: '#990200',
	YELLOW: '#F8980B',
	ORANGE: '#ED6706',
	GRAY: '#616875',
	GRAYB: '#777D88',
	GRAYC: '#E9ECF2',
	GREENA: '#12c912',
	GREENB: '#13B213',
	GREENC: '#008A00',
	GREEND: '#166E16',
	BLUE: '#3E87F8',
	DARK_BLUE: '#1B273C',
	LIGHT_GRAY: '#A4A8AE',
};

export const RESOURCE_OVERVIEW = {
	LASTSEEN: {
		last5Mins: '5 min',
		last30Mins: '30 min',
		last60Mins: '60 min',
		more60Mins: '60+ min',
	},
	LASTSEEN_COLORS: {
		last5Mins: DASHBOARD_COLORS.GREENB,
		last30Mins: DASHBOARD_COLORS.GREEND,
		last60Mins: DASHBOARD_COLORS.ORANGE,
		more60Mins: DASHBOARD_COLORS.REDB,
	},
	HEALTH: {
		blockerIssues: 'Blocker Issues',
		criticalIssues: 'Critical Issues',
		significantIssues: 'Significant Issues',
		minorIssues: 'Minor Issues',
		operational: 'Operational',
		unknown: 'Unknown',
	},
	HEALTH_COLORS: {
		blockerIssues: DASHBOARD_COLORS.REDC,
		criticalIssues: DASHBOARD_COLORS.REDA,
		minorIssues: DASHBOARD_COLORS.YELLOW,
		operational: DASHBOARD_COLORS.GREENC,
		unknown: DASHBOARD_COLORS.GRAY,
		significantIssues: DASHBOARD_COLORS.ORANGE,
	},
	FREESPACE: {
		free25: {
			label: '< 25%',
			color: '#d00300',
			description: 'Less than 25% free space',
		},
		free50: {
			label: '25 - 50%',
			color: '#ed6706',
			description: 'Between 25-50% free space',
		},
		free75: {
			label: '51 - 75%',
			color: '#166e16',
			description: 'Between 51-75% free space',
		},
		free100: {
			label: '> 75%',
			color: '#008a00',
			description: 'Greater than 75% free space',
		},
	},
	FREESPACE_COLORS: {
		colors: [DASHBOARD_COLORS.REDB, DASHBOARD_COLORS.ORANGE, DASHBOARD_COLORS.GREEND, DASHBOARD_COLORS.GREENC],
	},
};

export const HEATH_CHART: IChartInfo = {
	labels: [],
	data: [],
	colors: [DASHBOARD_COLORS.REDA, DASHBOARD_COLORS.YELLOW, DASHBOARD_COLORS.GREENC],
	padding: {
		left: 0,
		top: -10,
	},
	isEnabledTooltip: true,
};

export const LASTSEEN_CHART: IChartInfo = {
	labels: [],
	data: [],
	colors: [DASHBOARD_COLORS.GREENB, DASHBOARD_COLORS.GREEND, DASHBOARD_COLORS.ORANGE, DASHBOARD_COLORS.REDB],
	padding: {
		left: 1,
		top: -10,
	},
	isEnabledTooltip: true,
};

export const VERSION_CHART: IChartInfo = {
	labels: [],
	data: [],
	colors: ['#3D4A68'],
	padding: {
		left: 1,
		top: -10,
	},
	yAxisMax: 0,
	isEnabledTooltip: true,
};

export enum FILTER_ENUM {
	ONEDAY = '24H',
	SEVENDAYS = '7D',
	ONEMONTH = '30D',
	ONEYEAR = '12M',
	CUSTOM = 'CUSTOM',
}

export const FILTER_OPTIONS: IFilterOption[] = [
	{
		name: FILTER_ENUM.ONEDAY,
		value: {
			amount: 24,
			unit: UNIT_FILTER.HOUR,
		},
	},
	{
		name: FILTER_ENUM.SEVENDAYS,
		value: {
			amount: 7,
			unit: UNIT_FILTER.DAY,
		},
	},

	{
		name: FILTER_ENUM.ONEMONTH,
		value: {
			amount: 30,
			unit: UNIT_FILTER.DAY,
		},
	},

	{
		name: FILTER_ENUM.ONEYEAR,
		value: {
			amount: 12,
			unit: UNIT_FILTER.MONTH,
		},
	},
	{
		name: FILTER_ENUM.CUSTOM,
		value: {
			amount: 0,
			unit: UNIT_FILTER.DAY,
		},
	},
];
