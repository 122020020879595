<div class="container">
	<div class="content-body">
		<div class="flex flex-space-between m-b-md">
			<h3>My Products</h3>
			<span class="m-t-md">Updated: {{ lastUpdate | timezoneDate : 'MMM D, hh:mm A' || 'N/A' }}</span>
		</div>

		<ng-containner *ngIf="!isLoading; else showloading">
			<ng-containner *ngIf="incidents?.length">
				<div class="incident-notifications" [@detailExpand]="incidents.length > 0 ? 'expanded' : 'collapsed'">
					<ng-containner *ngFor="let incident of incidents; let i = index">
						<app-inline-notification *ngIf="i < numOfIncidentShown" type="{{ incident.displaySeverity }}"
							(onClose)="onCloseNotification(incident.id)"
							[disabled]="permission === PERMISSION.VIEW_ONLY ">
							<div title class="flex flex-space-between">
								<div class="w-60-p" [ngClass]="{'flex': !incident.viewFull, 'd-inline': incident.viewFull }">
									<span class="incident-notifications--text description" [ngClass]="{'view-more':!incident.viewFull, 'd-inline': incident.viewFull }">
										<strong>{{ incident.issue }} </strong>detected on {{ incident.productName }} \ {{ incident.instanceName }}
									</span>
									<a class="p-l-sm" (click)="viewMore(incident)"> {{incident.viewFull ? 'hide' : 'more'}}</a>
								</div>
								<span class="incident-notifications--text time m-l-sm">{{
									incident.startTime | timezoneDate : 'MMM D, YYYY, h:mm:ss A' || 'N/A'
									}}</span>
							</div>
						</app-inline-notification>
					</ng-containner>
					<ng-containner *ngIf="incidents.length - numOfIncidentShown > 0">
						<div class="m-t-md m-b-md m-l-sm flex flex-align-items-end">
							<mat-icon class="is-large color-warn m-r-md" svgIcon="vulnerability"></mat-icon>
							<span class="m-r-md"><strong>{{ this.incidents.length - numOfIncidentShown }}</strong> More
								Security Issues</span>
							<a (click)="openIncidentAlertPannel()">View more</a>
						</div>
					</ng-containner>
				</div>
			</ng-containner>
			<ng-containner *ngIf="products.total > 0; else noproduct">
				<mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedTabIndex"
					(selectedIndexChange)="updateTabchange()">
					<mat-tab label="Assets Operation">
						<ng-containner *ngIf="!isTabLoading; else showloading">
							<ng-containner *ngIf="products.managed.length > 0">
								<h4 class="m-b-lg m-t-xl">Managed Products</h4>
								<div class="product-list m-b-xl">
									<div class="product-card" *ngFor="let product of products.managed; let i = index">
										<div class="product-card__header">
											<div class="product-card__header__title">
												<div class="product-card__header__title__name">
													<span [class.cursor-pointer]="product.total > 0"
														(click)="product.total > 0 ? goToProduct(product.type) : null">{{
														product.name }}</span>
													<div class="product-card__header__title__info">
														<strong>{{ product.total || 'No' }}</strong> instance[s] managed
													</div>
												</div>
												<img class="product-card__header__title__icon"
													[class.cursor-pointer]="product.total > 0"
													(click)="product.total > 0 ? goToProduct(product.type) : null"
													[src]="product.image" alt="product icon" />
											</div>
										</div>
										<div class="product-card__content">
											<ng-containner [ngTemplateOutlet]="productinfo"
												[ngTemplateOutletContext]="{ $implicit: product }"></ng-containner>
										</div>
									</div>
								</div>
							</ng-containner>
							<ng-containner *ngIf="products.unmanaged.length > 0">
								<h4 class="m-b-lg m-t-xl">Unmanaged Products</h4>
								<div class="product-list m-b-xl">
									<div class="product-card" *ngFor="let product of products.unmanaged; let i = index">
										<div class="product-card__header">
											<div class="product-card__header__title">
												<div class="product-card__header__title__name">
													<span [class.cursor-pointer]="product.total > 0"
														(click)="product.total > 0 ? goToProduct(product.type) : null">{{
														product.name }}</span>
												</div>
												<img class="product-card__header__title__icon"
													[class.cursor-pointer]="product.total > 0"
													(click)="product.total > 0 ? goToProduct(product.type) : null"
													[src]="product.image" alt="product icon" />
											</div>
										</div>
										<div class="product-card__content">
											<ng-containner [ngTemplateOutlet]="productencroll"
												[ngTemplateOutletContext]="{ $implicit: product }"></ng-containner>
										</div>
									</div>
								</div>
							</ng-containner>
						</ng-containner>
					</mat-tab>
					<mat-tab>
						<ng-template mat-tab-label>
							<div>
								Security
								<mat-icon *ngIf="hasIncident" class="is-small color-warn"
									svgIcon="product-individual"></mat-icon>
							</div>
						</ng-template>

						<div class="security-container min-h-400">
							<div class="m-t-md" *ngIf="environment.onCloud">
								Note: Security report feature was introduced from version 2024.1.2(Jan 31, 2024) of My
								OPSWAT. Report data
								that is older than Jan 31, 2024 will not be displayed here.
							</div>
							<div class="m-t-md flex flex-align-items-end flex-end">
								<div class="flex flex-middle position-relative">
									<div class="calendar" *ngIf="showCalendar">
										<ngx-daterangepicker-material #dateRangePicker (chosenDate)="chosenDate($event)"
											(cancelClicked)="cancelClicked($event)"
											(datesUpdated)="datesUpdated($event)"
											[locale]="{ firstDay: 1, cancelLabel: 'Cancel', applyLabel: 'Confirm' }"
											[showCancel]="true" [showDropdowns]="true" [startDate]="startDate"
											[endDate]="endDate" [maxDate]="maxDate">
										</ngx-daterangepicker-material>
									</div>
									<div #filterOptionElements *ngFor="let filter of filterOptions"
										class="option text-medium is-clickable text-center"
										(click)="selectElement(filter)" id="{{ filter.name }}"
										[class.active]="selectedElement.name === filter.name">
										<span *ngIf="filter.name !== 'CUSTOM'; else elseBlock">
											{{ filter.name }}
										</span>
										<ng-template #elseBlock>
											<mat-icon (click)="setCalendarMatIconEvent($event)" svgIcon="calendar"
												class="calendar-icon"></mat-icon>
										</ng-template>
									</div>
									<button mat-icon-button color="ghost" class="icon-button m-l-sm z-index-1"
										(click)="openCustomIncidentDialog()">
										<mat-icon matTooltip="Security Alert Preferences"
											svgIcon="dashboard"></mat-icon>
									</button>
								</div>
							</div>
							<ng-containner *ngIf="!isTabLoading; else showloading">
								<div class="incident-list">
									<ng-container *ngFor="let item of incidentAllProducts">
										<div class="incident-card">
											<p class="incident-card__number flex flex-center">{{ item.value }}</p>
											<div class="incident-card__catalog flex flex-center flex-align-items-end">
												<ng-container [ngSwitch]="item.type">
													<mat-icon *ngSwitchCase="'THREAT'"
														class="incident-card--threat incident-card--{{ getIncidentSeverity(item.type) }} "
														svgIcon="malware-2"></mat-icon>
													<mat-icon *ngSwitchCase="'SENSITIVE_DATA'"
														class="incident-card--sensitive incident-card--{{ getIncidentSeverity(item.type) }}"
														svgIcon="vulnerability"></mat-icon>
													<mat-icon *ngSwitchCase="'SUSPICIOUS'" class="incident-card--suspicious incident-card--{{
															getIncidentSeverity(item.type)
														}}" svgIcon="vulnerability"></mat-icon>
													<mat-icon *ngSwitchCase="'POTENTIALLY_VULNERABLE'" class="incident-card--vulnerable incident-card--{{
															getIncidentSeverity(item.type)
														}}" svgIcon="vulnerability"></mat-icon>
													<mat-icon *ngSwitchCase="'OTHERS'"
														class="incident-card--other incident-card--{{ getIncidentSeverity(item.type) }}"
														svgIcon="vulnerability"></mat-icon>
												</ng-container>

												<span class="incident-card--catelog-name p-l-sm"> {{ item.name }}</span>
											</div>
										</div>
									</ng-container>
								</div>
								<div class="table-wrapper m-t-lg" *ngIf="incidentByProducts.length > 0">
									<table mat-table aria-label="Incident by products table"
										[dataSource]="incidentByProducts" multiTemplateDataRows
										class="mat-elevation-z8 security-table w-100-p">
										<ng-container matColumnDef="productName">
											<th mat-header-cell *matHeaderCellDef scope="version">Product Name</th>
											<td mat-cell *matCellDef="let element">
												<span [class.is-clickable]="false">
													{{ element.productName }}
												</span>
											</td>
										</ng-container>
										<ng-container matColumnDef="threat">
											<th mat-header-cell *matHeaderCellDef scope="version">Threats</th>
											<td mat-cell *matCellDef="let element">
												<span [class.ui-disabled]="element.threat === 0"
													class="incident-{{ getIncidentSeverity('THREAT') }} is-clickable"
													(click)="openIncidentDetailPannel('threat', element.productType)">
													{{ element.threat || '-' }}
												</span>
											</td>
										</ng-container>
										<ng-container matColumnDef="sensitiveData">
											<th mat-header-cell *matHeaderCellDef scope="version">Sensitive Data</th>
											<td mat-cell *matCellDef="let element">
												<span [class.ui-disabled]="element.sensitiveData === 0"
													class="incident-{{ getIncidentSeverity('SENSITIVE_DATA') }} is-clickable"
													(click)="openIncidentDetailPannel('sensitiveData', element.productType)">
													{{ element.sensitiveData || '-' }}
												</span>
											</td>
										</ng-container>
										<ng-container matColumnDef="suspicious">
											<th mat-header-cell *matHeaderCellDef scope="version">Suspicious Files</th>
											<td mat-cell *matCellDef="let element">
												<span [class.ui-disabled]="element.suspicious === 0"
													class="incident-{{ getIncidentSeverity('SUSPICIOUS') }} is-clickable"
													(click)="openIncidentDetailPannel('suspicious', element.productType)">
													{{ element.suspicious || '-' }}
												</span>
											</td>
										</ng-container>
										<ng-container matColumnDef="potentiallyVulnerable">
											<th mat-header-cell *matHeaderCellDef scope="version">Potential
												Vulnerabilities</th>
											<td mat-cell *matCellDef="let element">
												<span [class.ui-disabled]="element.potentiallyVulnerable === 0"
													class="incident-{{ getIncidentSeverity('POTENTIALLY_VULNERABLE') }} is-clickable"
													(click)="openIncidentDetailPannel('potentiallyVulnerable', element.productType)">
													{{ element.potentiallyVulnerable || '-' }}
												</span>
											</td>
										</ng-container>

										<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>

										<tr mat-row *matRowDef="let element; columns: columnsToDisplay"
											class="example-element-row"></tr>
									</table>
								</div>
							</ng-containner>
						</div>
					</mat-tab>
				</mat-tab-group>
			</ng-containner>
		</ng-containner>
	</div>
</div>
<ng-template #showloading>
	<app-loading-error-indicator [nrRows]="15"></app-loading-error-indicator>
</ng-template>
<ng-template #noproduct>
	<h3 class="no-product-title">Overview</h3>
	<div class="no-product-overview">
		<div class="no-product-body">
			<span class="no-product-content">
				<span class="lable"> No Product Managed Yet </span>
				<div class="line-break"></div>
				<span>
					Product Enrollment is done directly on the product instance. Enter the product registration code and
					the My OPSWAT
					Management URL into the enrollment screen on the instance.
				</span>
				<span>
					Full detailed step-by-step information can be found on
					<a [href]="urlDocument" target="_blank">docs.opswat.com/</a>
				</span>
			</span>
		</div>
		<div class="no-product-body">
			<span class="no-product-content">
				<span class="lable"> Enrollment instruction </span>
				<div class="line-break"></div>
				<span>It’s easy to get started follow the steps below</span>
				<ol class="list-step-enroll">
					<li>
						<span>Copy the following information</span>
					</li>
					<div class="content-step-1">
						<strong>Registration Code</strong>
						<span class="link">
							{{ aboutOneCentral?.regCode }}
							<button (click)="copyTempPassword(aboutOneCentral?.regCode)" mat-icon-button>
								<mat-icon class="is-small" svgIcon="copy"></mat-icon>
							</button>
						</span>
					</div>
					<div class="content-step-1">
						<strong>My OPSWAT URL</strong>
						<span class="link">
							{{ OCMURL }}
							<button (click)="copyTempPassword(OCMURL)" mat-icon-button>
								<mat-icon class="is-small" svgIcon="copy"></mat-icon>
							</button>
						</span>
					</div>
					<li class="m-t-20">In the product, navigate to the (page) and enter the requested information.</li>
					<li class="m-t-20">Return here My OPSWAT and refresh to complete the enrollment.</li>
				</ol>
			</span>
			<mat-divider></mat-divider>
			<span class="link-document">
				More details including a step-by-step guide is available in OPSWAT's online product documentation
				<a [href]="urlDocument" target="_blank">docs.opswat.com/</a>
			</span>
		</div>
	</div>
</ng-template>
<ng-template #productencroll let-product>
	<div class="product-card__content__learn-more flex flex-column flex-space-between">
		<div>
			Your <strong>{{ product.name }}</strong> is not enrolled in My OPSWAT Product Management
		</div>
		<button [style.width.%]="100" id="encrollBtn" mat-button color="primary"
			(click)="showHowToEnroll(product.type)">
			Learn to enroll the product
		</button>
	</div>
</ng-template>
<ng-template #learnMore let-product>
	<div class="product-card__content__learn-more flex flex-column flex-space-between">
		<div class="product-card__content__learn-more__intro" [innerHTML]="product.description"></div>
		<a class="product-card__content__learn-more__link" id="encrollBtn" color="primary"
			(click)="learnMoreInMyOpswat(product.slug)">
			Learn more
		</a>
	</div>
</ng-template>
<ng-template #productinfo let-product>
	<div class="product-card__content__status">
		<div class="product-card__content__status__item" *ngIf="product?.connectivity">
			<span>Connectivity</span>
			<div class="product-card__content__status__chart">
				<apx-chart #connectivity [series]="product.connectivity.series" [chart]="product.connectivity.chart"
					[labels]="product.connectivity.labels" [responsive]="product.connectivity.responsive"
					[plotOptions]="product.connectivity.plotOptions" [dataLabels]="product.connectivity.dataLabels"
					[legend]="product.connectivity.legend" [colors]="product.connectivity.colors"
					[grid]="product.connectivity.grid" [stroke]="product.connectivity.stroke"
					[states]="product.connectivity.states"></apx-chart>
			</div>
		</div>
		<div class="product-card__content__status__item" *ngIf="product?.health">
			<span>Health</span>
			<div class="product-card__content__status__chart">
				<apx-chart #health [series]="product.health.series" [chart]="product.health.chart"
					[labels]="product.health.labels" [responsive]="product.health.responsive"
					[plotOptions]="product.health.plotOptions" [dataLabels]="product.health.dataLabels"
					[legend]="product.health.legend" [colors]="product.health.colors" [grid]="product.health.grid"
					[yaxis]="product.health.yaxis" [xaxis]="product.health.xaxis" [stroke]="product.health.stroke"
					[tooltip]="product.health.tooltip"></apx-chart>
			</div>
		</div>
		<div class="product-card__content__status__item" *ngIf="product?.versions">
			<span>Versions</span>
			<div class="product-card__content__status__chart">
				<apx-chart #version [series]="product.versions.series" [chart]="product.versions.chart"
					[labels]="product.versions.labels" [responsive]="product.versions.responsive"
					[plotOptions]="product.versions.plotOptions" [dataLabels]="product.versions.dataLabels"
					[legend]="product.versions.legend" [colors]="product.versions.colors" [grid]="product.versions.grid"
					[yaxis]="product.versions.yaxis" [xaxis]="product.versions.xaxis" [stroke]="product.versions.stroke"
					[tooltip]="product.versions.tooltip"></apx-chart>
			</div>
		</div>
		<div class="product-card__content__status__item"
			*ngIf="product?.licenses && OPSWAT_PRODUCT[product.type]?.LICENSING !== 0">
			<span>Licenses</span>
			<div class="product-card__content__status__chart">
				<apx-chart #license [series]="product.licenses.series" [chart]="product.licenses.chart"
					[labels]="product.licenses.labels" [responsive]="product.licenses.responsive"
					[plotOptions]="product.licenses.plotOptions" [dataLabels]="product.licenses.dataLabels"
					[legend]="product.licenses.legend" [colors]="product.licenses.colors" [grid]="product.licenses.grid"
					[yaxis]="product.licenses.yaxis" [xaxis]="product.licenses.xaxis" [stroke]="product.licenses.stroke"
					[tooltip]="product.licenses.tooltip"></apx-chart>
			</div>
		</div>
	</div>
</ng-template>
