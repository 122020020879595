import { Injectable } from '@angular/core';
import { RequestService } from '../request.service';
import { Observable } from 'rxjs';
import { UPGRADE_OVA } from './upgrade-ova.api';

@Injectable({
	providedIn: 'root',
})
export class UpgradeOvaService {
	constructor(private readonly requestService: RequestService) {}

	public checkSystemIsUpgrading(): Observable<any> {
		return this.requestService.action(UPGRADE_OVA.checkUpgradeOVA);
	}

	public checkImportingFile(): Observable<any> {
		return this.requestService.action(UPGRADE_OVA.CHECK_IMPORT_FILE);
	}

	public checkStorageSpace(): Observable<any> {
		return this.requestService.action(UPGRADE_OVA.CHECK_STORAGE_SPACE);
	}
}
