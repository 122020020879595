import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OPSWAT_PRODUCTS } from '@app/shared/constants';
import { CmUtilService, ToastService } from '@app/shared/services';
import { PublisherService } from '@app/shared/services/publisher/publisher.service';
import { environment } from '@env/environment';
import { CONSTANT } from '@app/shared/constants/common.constant';
import { SettingsService } from '../../../settings/services';
import { showHttpErrorMsg } from '@app/shared/utils';

@Component({
	selector: 'app-how-to-enroll-dialog',
	templateUrl: './how-to-enroll-dialog.component.html',
	styleUrls: ['./how-to-enroll-dialog.component.scss'],
})
export class HowToEnrollDialogComponent implements OnInit {
	OCMURL = environment.productUrl;
	regCode = 'N/A';
	product: any;
	isLoading = true;

	constructor(
		public dialogRef: MatDialogRef<HowToEnrollDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		public readonly utilService: CmUtilService,
		private readonly publisherService: PublisherService,
		private readonly settingsService: SettingsService,
		private readonly toastService: ToastService
	) {}

	ngOnInit(): void {
		this.product = OPSWAT_PRODUCTS[this.data.type];
		this.getLicenseAndAccountInfo();
	}

	goToGuideDoc() {
		const link = this.product?.DOC ? this.product?.DOC : CONSTANT.PRODUCT_DOCUMENTATION;
		window.open(link);
	}

	getLicenseAndAccountInfo() {
		this.settingsService.getAboutOneCentral().subscribe(
			(response: any) => {
				this.regCode = response.regCode;
				this.isLoading = false;
			},
			({ error, status }) => {
				if (error) {
					this.isLoading = false;
					showHttpErrorMsg(this.toastService, status, 'get account information', error.error);
				}
			}
		);
	}
}
