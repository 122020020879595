import { environment } from '@env/environment';

export const CONSTANT = {
	CONTENT_TYPE: 'Content-Type',
	APPLICATION_TYPE: {
		JSON: 'application/json;charset=UTF-8',
		CSV: 'text/csv;charset=utf-8;',
		PDF: 'application/pdf;charset=utf-8;',
		TXT: 'text/plain;charset=utf-8;',
	},
	AUTH: {
		DX_TOKEN: '__opswat-my-tk',
		JWT_TOKEN: 'ocmJwtToken',
		TIME_LIMIT: 1800, // 30p
		LAST_API_CALL: 'ocmLastApiCall',
		JWT_STATUS: 'tkn-stts',
		JWT_TIME: 'tkn-tmstm',
	},
	COOKIE_HOST_PREFIX: '__Host-',
	HTTP_METHOD: {
		GET: 'GET',
		POST: 'POST',
		DELETE: 'DELETE',
		PUT: 'PUT',
		HEAD: 'HEAD',
		PATCH: 'PATCH',
	},
	URL_PREFIX: environment.ocmAPIServer + '/api/console/',
	URL_WITHOUT_PREFIX: environment.ocmAPIServer + '/',
	VAULT_URL_PREFIX: environment.vaultAPIServer + '/',
	SNACK_BAR_DURATION: 4000,
	EMPTY_STRING: '',
	CHAT_WITH_EXPERT: 'https://go2.opswat.com/login?sc=0LE4X000000k9dd',
	CONTACT_OPSWAT: 'https://www.opswat.com/contact/',
	COMMUNITY_PORTAL: 'https://go2.opswat.com/login?sc=0LE4X000000k9dd',
	OPSWAT_CHANNEL: 'https://opswat.allbound.com/',
	PRODUCT_DOCUMENTATION: 'https://docs.opswat.com/',
	MY_OPSWAT: 'https://my.opswat.com ',
	MO_PRODUCT_DOCUMENTATION: 'https://docs.opswat.com/myop',

	EMPTY_RESULT: {
		INSTANCE_LIST: 'No instances found for given search/filter criteria.',
		INSTANCE_DETAIL_FIRST_SEARCH_EMPTY: 'This instance has not submitted any reports',
		INSTANCE_DETAIL_SEARCH_EMPTY: 'No reports found for given search/filter criteria.',
		USER_MANAGEMENT: 'No users/groups found for given search/filter criteria.',
		MANAGED_ACCOUNT: 'There are no account matching your search or filter.',
		INSTANCE_RESULTS: 'No instances found for given search/filter criteria.',
		GROUP_LIST: 'No groups found for given search/filter criteria.',
		GROUP_LIST_IN_ROLE_PAGE: 'No groups found',
		GROUP_DETAIL: 'No instances found for given search/filter criteria.',
		PRODUCT_LIST: 'No products found',
		CERTIFICATE_SETTING: 'No certificates found',
		USER_DIRECTORY: 'No directory found for given search/filter criteria.',
		PROCESSING_HISTORY: 'No reports found for given search/filter criteria.',
		EXPORT_FILE_LIST: 'No export file found',
	},
	CUSTOM_FILTER_ERROR: 'End date cannot be earlier than Start date.',
	COLOR: {
		SUCCESS: 'color-success',
		WARN: 'color-warn',
		HIGHLIGHT: 'color-highlight',
	},

	COLOR_LICENSE: {
		OVER_90: 'color-over-90',
		FROM_31_TO_90: 'color-from-31-to-90',
		FROM_1_TO_30: 'color-from-1-to-30',
		EXPIRED: 'color-expired',
		NO_LICENSE: 'color-no-license',
	},
	MAXIMUM_ROLE_RECORD: 100,
	MAXIMUM_ACCOUNT_RECORD: 100,
	MAXIMUM_GROUP_INSTANCE_RECORD: 100,
	NO_LICENSE: 'No License',
	BUNDLE: 'Bundle',
	EXTERNAL_LINK_ICON: 'external-link',
	TEXT_MEDIUM: 'text-medium',
	MAXIMUM_PAGE_RECORD: 100,
	DIRECTORY: {
		DEFAULT_DIR: 'LOCAL',
		DEFAULT_AD: 'AD',
	},
	DEBUG_FLAG: 'recallMofConsole',
	COOKIE_KEY: 'cn',
	MY_OPSWAT_COOKIES: ['__opswat-login', '__opswat-payload-login', '__opswat-refresh-login', '__opswat-session-login'],
	CREATE_ROLE_ERROR_MESSAGE: 'Create role',
};
