<div class="dialog-small__header">
	<h4>Warning</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<ng-container *ngIf="data.reason === removedError.ACCOUNT">
		<div class="m-b-lg m-t-md">
			<div>
				You were removed from the account <span class="text-medium">{{ data.accountName }}</span
				>.
			</div>
			<span>Sign in again to access other accounts if any.</span>
		</div>
	</ng-container>
	<ng-container *ngIf="data.reason === removedError.SSO">
		<div class="m-b-lg m-t-md">
			<div>Your session has ended because your permissions were updated or your user was removed.</div>
			<span>Please try logging in again or contact your administrators for assistance.</span>
		</div>
	</ng-container>
</div>
<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-end m-t-md">
		<button mat-button color="primary" (click)="terminate()">Close</button>
	</div>
</div>
