// dayjs-config.ts
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

/// <reference path="./../../../../node_modules/dayjs/plugin/relativeTime.d.ts" />
// we don't use it anymore but i want to keep it as reference object.
// const TIME_ZONES: Record<string, string> = {
// 	'Etc/GMT+12': '-12:00',
// 	'Pacific/Midway': '-11:00',
// 	'America/Adak': '-10:00',
// 	'Pacific/Honolulu': '-10:00',
// 	'Pacific/Marquesas': '-09:30',
// 	'America/Juneau': '-09:00',
// 	'Etc/GMT+9': '-09:00',
// 	'America/Tijuana': '-08:00',
// 	'Etc/GMT+8': '-08:00',
// 	'America/Vancouver': '-08:00',
// 	'America/Creston': '-07:00',
// 	'America/Mazatlan': '-07:00',
// 	'America/Boise': '-07:00',
// 	'Canada/Yukon': '-07:00',
// 	'America/Belize': '-06:00',
// 	'America/Chicago': '-06:00',
// 	'Pacific/Easter': '-06:00',
// 	'America/Bahia_Banderas': '-06:00',
// 	'America/Regina': '-06:00',
// 	'America/Bogota': '-05:00',
// 	'America/Cancun': '-05:00',
// 	'America/New_York': '-05:00',
// 	'America/Toronto': '-05:00',
// 	'America/Havana': '-05:00',
// 	'America/Indiana/Marengo': '-05:00',
// 	'America/Indianapolis': '-05:00',
// 	'America/Asuncion': '-04:00',
// 	'Atlantic/Bermuda': '-04:00',
// 	'America/Caracas': '-04:00',
// 	'America/Cuiaba': '-04:00',
// 	'America/Puerto_Rico': '-04:00',
// 	'America/Santiago': '-04:00',
// 	'America/St_Johns': '-03:30',
// 	'America/Araguaina': '-03:00',
// 	'America/Sao_Paulo': '-03:00',
// 	'America/Cayenne': '-03:00',
// 	'America/Argentina/Buenos_Aires': '-03:00',
// 	'America/Godthab': '-03:00',
// 	'America/Montevideo': '-03:00',
// 	'America/Punta_Arenas': '-03:00',
// 	'America/Miquelon': '-03:00',
// 	'America/Bahia': '-03:00',
// 	'Etc/GMT+2': '-02:00',
// 	'Atlantic/Azores': '-01:00',
// 	'Atlantic/Cape_Verde': '-01:00',
// 	'Etc/GMT': '+00:00',
// 	'Europe/London': '+00:00',
// 	'Africa/Monrovia': '+00:00',
// 	'Africa/Sao_Tome': '+00:00',
// 	'Europe/Amsterdam': '+01:00',
// 	'Europe/Belgrade': '+01:00',
// 	'Europe/Paris': '+01:00',
// 	'Europe/Sarajevo': '+01:00',
// 	'Africa/Algiers': '+01:00',
// 	'Europe/Athens': '+02:00',
// 	'Asia/Beirut': '+02:00',
// 	'Africa/Cairo': '+02:00',
// 	'Europe/Chisinau': '+02:00',
// 	'Asia/Damascus': '+02:00',
// 	'Asia/Gaza': '+02:00',
// 	'Africa/Harare': '+02:00',
// 	'Europe/Helsinki': '+02:00',
// 	'Asia/Jerusalem': '+02:00',
// 	'Africa/Juba': '+02:00',
// 	'Europe/Kaliningrad': '+02:00',
// 	'Africa/Khartoum': '+02:00',
// 	'Africa/Tripoli': '+02:00',
// 	'Africa/Windhoek': '+02:00',
// 	'Asia/Amman': '+03:00',
// 	'Asia/Baghdad': '+03:00',
// 	'Europe/Istanbul': '+03:00',
// 	'Asia/Kuwait': '+03:00',
// 	'Europe/Minsk': '+03:00',
// 	'Europe/Moscow': '+03:00',
// 	'Africa/Nairobi': '+03:00',
// 	'Europe/Volgograd': '+03:00',
// 	'Asia/Tehran': '+03:30',
// 	'Asia/Dubai': '+04:00',
// 	'Europe/Astrakhan': '+04:00',
// 	'Asia/Baku': '+04:00',
// 	'Europe/Samara': '+04:00',
// 	'Indian/Mahe': '+04:00',
// 	'Europe/Saratov': '+04:00',
// 	'Asia/Tbilisi': '+04:00',
// 	'Asia/Yerevan': '+04:00',
// 	'Asia/Kabul': '+04:00',
// 	'Asia/Ashgabat': '+05:00',
// 	'Asia/Yekaterinburg': '+05:00',
// 	'Asia/Karachi': '+05:00',
// 	'Asia/Qyzylorda': '+05:00',
// 	'Asia/Kolkata': '+05:30',
// 	'Asia/Colombo': '+05:30',
// 	'Asia/Kathmandu': '+05:45',
// 	'Asia/Urumqi': '+06:00',
// 	'Asia/Dhaka': '+06:00',
// 	'Asia/Omsk': '+06:00',
// 	'Asia/Rangoon': '+06:30',
// 	'Asia/Bangkok': '+07:00',
// 	'Asia/Barnaul': '+07:00',
// 	'Asia/Hovd': '+07:00',
// 	'Asia/Krasnoyarsk': '+07:00',
// 	'Asia/Novosibirsk': '+07:00',
// 	'Asia/Tomsk': '+07:00',
// 	'Asia/Hong_Kong': '+08:00',
// 	'Asia/Irkutsk': '+08:00',
// 	'Asia/Kuala_Lumpur': '+08:00',
// 	'Australia/Perth': '+08:00',
// 	'Asia/Taipei': '+08:00',
// 	'Asia/Ulaanbaatar': '+08:00',
// 	'Australia/Eucla': '+08:45',
// 	'Asia/Chita': '+09:00',
// 	'Asia/Tokyo': '+09:00',
// 	'Asia/Pyongyang': '+09:00',
// 	'Asia/Seoul': '+09:00',
// 	'Asia/Yakutsk': '+09:00',
// 	'Australia/Adelaide': '+09:30',
// 	'Australia/Darwin': '+09:30',
// 	'Australia/Brisbane': '+10:00',
// 	'Australia/Sydney': '+10:00',
// 	'Pacific/Guam': '+10:00',
// 	'Australia/Hobart': '+10:00',
// 	'Asia/Vladivostok': '+10:00',
// 	'Australia/Lord_Howe': '+10:30',
// 	'Pacific/Bougainville': '+11:00',
// 	'Asia/Magadan': '+11:00',
// 	'Asia/Sakhalin': '+11:00',
// 	'Etc/GMT-11': '+11:00',
// 	'Asia/Anadyr': '+12:00',
// 	'Pacific/Auckland': '+12:00',
// 	'Etc/GMT-12': '+12:00',
// 	'Pacific/Fiji': '+12:00',
// 	'Pacific/Chatham': '+12:45',
// 	'Etc/GMT-13': '+13:00',
// 	'Pacific/Fakaof': `+13:00) Nuku'alofa`,
// 	'Pacific/Apia': '+13:00',
// 	'Pacific/Kiritimati': '+13:00',
// };

// strict thresholds
const thresholds = [
	{ l: 's', r: 59, d: 'second' },
	{ l: 'm', r: 1 },
	{ l: 'mm', r: 59, d: 'minute' },
	{ l: 'h', r: 1 },
	{ l: 'hh', r: 23, d: 'hour' },
	{ l: 'd', r: 1 },
	{ l: 'dd', r: 29, d: 'day' },
	{ l: 'M', r: 1 },
	{ l: 'MM', r: 11, d: 'month' },
	{ l: 'y' },
	{ l: 'yy', d: 'year' },
];

const config = {
	thresholds: thresholds,
};

dayjs.extend(relativeTime, config);
dayjs.extend(utc);

export const dateHelper = {
	fromNow: function (date: string | number): string {
		return dayjs(date).tz(dateHelper.getTimezone()).fromNow();
	},
	/**
	 *
	 * @param start '2/1/2021'
	 * @param end '3/1/2021'
	 * @returns 28
	 */
	getNumberOfDays: function (start: string, end: string): number {
		const date1 = new Date(start);
		const date2 = new Date(end);

		// One day in milliseconds
		const oneDay = 1000 * 60 * 60 * 24;

		// Calculating the time difference between two dates
		const diffInTime = date2.getTime() - date1.getTime();

		// Calculating the no. of days between two dates
		return Math.round(diffInTime / oneDay);
	},
	/**
	 *
	 * @param start 1640839025134
	 * @param end 1641293357742
	 * @returns 5
	 */
	getNumberOfDaysByTimestamp: function (start: number, end: number): number {
		// One day in milliseconds
		const oneDay = 1000 * 60 * 60 * 24;

		// Calculating the time difference between two dates
		const diffInTime = end - start;

		// Calculating the no. of days between two dates
		return Math.round(diffInTime / oneDay);
	},

	getUTCTimezone: function (includeUTC = true) {
		const decimal = {
			25: '15',
			5: '30',
			75: '45',
		};
		let offset = this.getDaylightTimezoneOffset();
		if (this.hasDST()) {
			offset = offset + 1;
		}
		const signOffset = offset > 0 ? '+' : '-';
		let newOffset = offset.toString();

		if (signOffset === '-') {
			newOffset = newOffset.substring(1);
		}

		const UTC = includeUTC ? `UTC` : '';
		if (newOffset.includes('.')) {
			newOffset = `${newOffset.split('.')[0].padStart(2, '0')}:${decimal[newOffset.split('.')[1]]}`;
			return `${UTC}${signOffset}${newOffset}`;
		}

		newOffset = newOffset.padStart(2, '0');
		return `${UTC}${signOffset}${newOffset}:00`;
	},

	getDaylightTimezoneOffset: function () {
		const today = new Date();
		const jan = new Date(today.getFullYear(), 0, 1);
		const jul = new Date(today.getFullYear(), 6, 1);
		return -Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset()) / 60;
	},

	hasDST(date = new Date()) {
		const january = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
		const july = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();

		return Math.max(january, july) !== date.getTimezoneOffset();
	},

	getTimezone() {
		const timezone = localStorage.getItem('timezone');
		if (timezone) {
			return timezone;
		}
		const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return browserTimezone;
	},

	getLocalTimezone() {
		return Intl.DateTimeFormat().resolvedOptions().timeZone;
	},

	formatUTCDate(timestamp: number, format: string) {
		return dayjs(timestamp).tz('UTC').format(format);
	},

	getFormatedUtcOffset(offsetMinutes: number) {
		const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60);
		const offsetMinutesRemainder = Math.abs(offsetMinutes) % 60;

		const sign = offsetMinutes >= 0 ? '+' : '-';
		const formattedOffset = `${sign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutesRemainder.toString().padStart(2, '0')}`;

		return formattedOffset;
	},
};
