import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { CONSTANT, PERMISSION, URL_CONSTANT } from '@app/shared/constants';
import { Observable } from 'rxjs';
import { RbacService } from '../../shared/services/rbac/rbac.service';

@Injectable({
	providedIn: 'root',
})
export class PermissionGuard {
	constructor(private readonly rbacService: RbacService, private readonly router: Router) {}

	canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
		return new Observable<boolean | UrlTree>((observer) => {
			const key = route?.data?.key || CONSTANT.EMPTY_STRING;
			const parentKey = route?.data?.parentKey || CONSTANT.EMPTY_STRING;
			const permission = this.rbacService.getPermission(key, parentKey);
			if (permission === PERMISSION.NONE) {
				return observer.next(this.router.parseUrl(URL_CONSTANT.PERMISSION_DENIED));
			} else {
				return observer.next(true);
			}
		});
	}
}
