export const HTTP_ERROR = {
	DEPLOYING: 0,
	NOT_FOUND: 404,
	BAD_REQUEST: 400,
	INTERNAL_SERVER: 500,
	UNAUTHORIZED: 401,
	ACCESS_DENIED: 403,
	GROUP_DELETED: 410,
	SERVER_DOWN_502: 502,
	SERVER_DOWN_503: 503,
	GATEWAY_TIME_OUT: 504,
	PIN_ERROR: 412,
	SUCCESS: 200,
};

export const MAXIMUM_NOTI_ATTEMPTS = 10;

export const REMOVED_USER_ERROR = {
	ACCOUNT: 'user.removed.from.account',
	SSO: 'user.removed.from.sso.group',
};
