export const OPTION = {
	MOVETOGROUP: {
		value: 'moveToGroup',
		viewValue: 'Move to group',
	},
	DELETE: {
		value: 'delete',
		viewValue: 'Delete',
		viewValueDeleteInstance: 'Delete Instance(s)',
		viewValueDeleteGroup: 'Delete Group(s)',
	},
	ACKNOWLEDGE: {
		value: 'acknowledge',
		viewValue: 'Acknowledge all threats',
	},
	EXPORTTOCSV: {
		value: 'exportToCSV',
		viewValue: 'Export to CSV',
	},
	APPLYGROUPCONFIGURATION: {
		value: 'applyGroupConfiguration',
		viewValue: 'Apply group configuration',
	},
	DONWLOAD: {
		JSON: {
			value: 'json',
			viewValue: 'Download report (json)',
		},
		PDF: {
			value: 'pdf',
			viewValue: 'Download report (pdf)',
		},
		TXT: {
			value: 'txt',
			viewValue: 'Download report (txt)',
		},
	},
	DEACTIVATELICENSE: {
		value: 'deactivateLicense',
		viewValue: 'Deactivate License(s)',
	},
	DELETELICENSE: {
		value: 'deleteLicense',
		viewValue: 'Delete License(s)',
	},
};

export const DROPDOWN_OPTION = {
	INSTANCE: [
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValue, disabled: true },
		{ value: OPTION.MOVETOGROUP.value, viewValue: OPTION.MOVETOGROUP.viewValue, disabled: true },
		{ value: OPTION.EXPORTTOCSV.value, viewValue: OPTION.EXPORTTOCSV.viewValue },
	],
	FIREWALL_INSTANCE: [
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValue, disabled: true },
		{ value: OPTION.MOVETOGROUP.value, viewValue: OPTION.MOVETOGROUP.viewValue, disabled: true },
		{ value: OPTION.EXPORTTOCSV.value, viewValue: OPTION.EXPORTTOCSV.viewValue },
	],
	GROUP: [{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValueDeleteGroup }],
	GROUP_DETAIL: [
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValueDeleteInstance, disabled: true },
		{ value: OPTION.MOVETOGROUP.value, viewValue: OPTION.MOVETOGROUP.viewValue, disabled: true },
		{ value: OPTION.EXPORTTOCSV.value, viewValue: OPTION.EXPORTTOCSV.viewValue },
	],
	GROUP_DETAIL_LEVEL1: [
		{ value: OPTION.APPLYGROUPCONFIGURATION.value, viewValue: OPTION.APPLYGROUPCONFIGURATION.viewValue, disabled: true },
	],
	SCAN_HISTORY: [
		{ value: OPTION.DONWLOAD.JSON.value, viewValue: OPTION.DONWLOAD.JSON.viewValue },
		{ value: OPTION.DONWLOAD.PDF.value, viewValue: OPTION.DONWLOAD.PDF.viewValue },
		{ value: OPTION.DONWLOAD.TXT.value, viewValue: OPTION.DONWLOAD.TXT.viewValue },
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValue, disabled: true },
	],
	PRODUCT_LICENSES: [{ value: OPTION.DELETELICENSE.value, viewValue: OPTION.DELETELICENSE.viewValue, disabled: true }],
	KIOSK_INSTANCE: [
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValue, disabled: true },
		{ value: OPTION.MOVETOGROUP.value, viewValue: OPTION.MOVETOGROUP.viewValue, disabled: true },
		{ value: OPTION.ACKNOWLEDGE.value, viewValue: OPTION.ACKNOWLEDGE.viewValue, disabled: true },
		{ value: OPTION.EXPORTTOCSV.value, viewValue: OPTION.EXPORTTOCSV.viewValue },
	],
	KIOSK_GROUP_DETAIL_INSTANCE: [
		{ value: OPTION.DELETE.value, viewValue: OPTION.DELETE.viewValueDeleteInstance, disabled: true },
		{ value: OPTION.MOVETOGROUP.value, viewValue: OPTION.MOVETOGROUP.viewValue, disabled: true },
		{ value: OPTION.ACKNOWLEDGE.value, viewValue: OPTION.ACKNOWLEDGE.viewValue, disabled: true },
		{ value: OPTION.EXPORTTOCSV.value, viewValue: OPTION.EXPORTTOCSV.viewValue },
	],
};
