<div class="dialog-xlarge incident-detail-container">
	<div class="dialog-xlarge__header">
		<h4>
			<span class=" incident-{{ data.severity }}">{{ total }}</span> {{ productName }} {{ issueName }} Detections
		</h4>
	</div>

	<div mat-dialog-content class="" (scroll)="onTableScroll($event)">
		<div class="" *ngIf="!isLoading; else showloading">
			<table
				mat-table
				aria-label="Security issues detection list"
				id="incident-detail-table"
				#table
				[dataSource]="incidents"
				class="w-100-p p-l-sm p-r-sm"
			>
				<ng-container matColumnDef="filePath">
					<th mat-header-cell *matHeaderCellDef>File Path</th>
					<td mat-cell *matCellDef="let element">
						<div class="max-w-200 word-break-all">{{ element.filePath }}</div>
					</td>
				</ng-container>
				<ng-container matColumnDef="instanceName">
					<th mat-header-cell *matHeaderCellDef scope="healthStatus">Instance Name</th>
					<td mat-cell *matCellDef="let element">
						<div
							class="max-w-100 ellipsis"
							#toolTipName
							matTooltip="{{ element.instanceName }}"
							[matTooltipDisabled]="toolTipName | toggleTooltipBaseOnWidthText"
						>
							{{ element.instanceName || '-' }}
						</div>
					</td>
				</ng-container>
				<ng-container matColumnDef="user">
					<th mat-header-cell *matHeaderCellDef scope="status">User</th>
					<td mat-cell *matCellDef="let element">
						{{ element.user || '-' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="startTime">
					<th mat-header-cell *matHeaderCellDef scope="version">Time Detected</th>
					<td mat-cell *matCellDef="let element">
						{{ element.startTime | timezoneDate : 'MMM D, YYYY hh:mm A' || '-' }}
					</td>
				</ng-container>
				<ng-container matColumnDef="mediaType">
					<th mat-header-cell *matHeaderCellDef scope="settings-version">Media Type</th>
					<td mat-cell *matCellDef="let element">
						{{ element.mediaType || '-' }}
					</td>
				</ng-container>
				<tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
				<tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
			</table>
		</div>
	</div>

	<div class="dialog-xlarge__footer">
		<div class="flex flex-end m-t-md">
			<div class="flex">
				<button id="incident-detail-closeBtn" mat-button color="primary" class="m-l-sm" (click)="onCancel()">Close</button>
			</div>
		</div>
	</div>
</div>
<ng-template #showloading>
	<app-loading-error-indicator [nrRows]="8"></app-loading-error-indicator>
</ng-template>
