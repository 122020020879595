import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CmUtilService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { UnsavedChangesDialogComponent } from '@app/shared/components/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DIALOG_WIDTH } from '@app/shared/constants';

@Injectable({
	providedIn: 'root',
})
export class UnsavedChangesGuard {
	constructor(private readonly utilService: CmUtilService, public matDialog: MatDialog) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		if (this.utilService.getPageTouched()) {
			const unsaveChangedRef = this.matDialog.open(UnsavedChangesDialogComponent, {
				disableClose: true,
				// height: DIALOG_HEIGHT.SMALL,
				width: DIALOG_WIDTH.SMALL,
			});
			return unsaveChangedRef.afterClosed();
		}
		return true;
	}
}
