<div class="dialog-large alert-setting-container">
	<div class="dialog-large__header">
		<h4>Security Alert Preferences</h4>
	</div>

	<div mat-dialog-content class="">
		<div class="table-wrapper" *ngIf="!isLoading; else showloading">
			<table
				mat-table
				aria-label="Security Alert Preferences"
				id="incident-detail-table"
				#table
				[dataSource]="alertSetting"
				class="w-100-p p-l-sm p-r-sm"
			>
				<ng-container matColumnDef="Incident">
					<th mat-header-cell *matHeaderCellDef>Issues</th>
					<td mat-cell *matCellDef="let element">
						{{ severityName[element.issue] }}
					</td>
				</ng-container>
				<ng-container matColumnDef="Alert Severity">
					<th mat-header-cell *matHeaderCellDef>Alert Severity</th>
					<td mat-cell *matCellDef="let element; let i = index">
						<span class="incident-{{ element.severity }}">{{ element.severity || '-' | titlecase }}</span>
					</td>
				</ng-container>
				<ng-container matColumnDef="Show Notification">
					<th mat-header-cell *matHeaderCellDef scope="healthStatus">Show Notification</th>
					<td mat-cell *matCellDef="let element">
						<mat-slide-toggle [disabled]="true" [(ngModel)]="element.showNotification"></mat-slide-toggle>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="columns"></tr>
				<tr mat-row *matRowDef="let row; columns: columns"></tr>
			</table>
		</div>
	</div>

	<div class="dialog-large__footer">
		<div class="flex flex-end m-t-md">
			<div class="flex">
				<button id="incident-alert-closeBtn" mat-button color="primary" class="m-l-sm" (click)="onCancel()">Close</button>
			</div>
		</div>
	</div>
</div>
<ng-template #showloading>
	<app-loading-error-indicator [nrRows]="8"></app-loading-error-indicator>
</ng-template>
