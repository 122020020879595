<div class="container">
	<div class="content-body">
		<app-page-title id="mdk-page-title" [breadcrumbItems]="breadcrumbItems"> </app-page-title>
		<div class="m-t-xl">
			<p>You don’t have permission to access this page. Your permission may be modified by an administrator.</p>
			<p>Please contact your administrators for help.</p>
			<mat-divider></mat-divider>
		</div>
	</div>
</div>
