import { Injectable, signal } from '@angular/core';
import { PERMISSION, PERMISSION_EXCLUDE_KEYS, PERMISSION_ORDERS, SETTING_PERMISSION_ORDERS } from '../../constants/permission.constant';

@Injectable({
	providedIn: 'root',
})
export class RbacService {
	permission = signal<any>(null);

	hasPermission() {
		const permission = this.permission();
		if (permission) {
			return true;
		}
		return false;
	}

	savePermission(permission: any) {
		this.permission.set(permission);
	}

	getPermission(page: string, parentPage = '') {
		const permission = this.permission();
		if (permission) {
			if (parentPage) {
				return permission[parentPage]?.[page] ?? PERMISSION.NONE;
			}
			return permission[page]?.level;
		}
		return PERMISSION.NONE;
	}

	sortPermissionByPageOrders(orderArray: any) {
		const permission = this.permission();
		return orderArray.reduce((acc: any, key: any) => {
			if (key in permission) {
				acc[key] = permission[key];
			}
			return acc;
		}, {});
	}

	sortPermissionBySettingOrders(arrayToSort: any) {
		return arrayToSort.sort((item1: any, item2: any) => {
			const indexA = SETTING_PERMISSION_ORDERS.indexOf(item1);
			const indexB = SETTING_PERMISSION_ORDERS.indexOf(item2);

			// If both elements are not in 'order', keep their relative positions
			if (indexA === -1 && indexB === -1) {
				return 0;
			}

			// If 'item1' is not in 'order', move it to the end
			if (indexA === -1) {
				return 1;
			}

			// If 'item2' is not in 'order', move it to the end
			if (indexB === -1) {
				return -1;
			}

			// If both elements are in 'order', sort them based on their position in 'order'
			return indexA - indexB;
		});
	}

	findChildPermission(childObject: any) {
		const excludeKeys = PERMISSION_EXCLUDE_KEYS;
		return Object.keys(childObject).filter(key => !excludeKeys.includes(key));
	}

	findFirstPageWithPermission(sortedPermission: any) {
		let page = 'permissionDenied';
		Object.keys(sortedPermission).some((key) => {
			const pagePermission = this.getPermission(key);
			if (!sortedPermission[key].isCustom) {
				if (pagePermission !== PERMISSION.NONE) {
					page = key;
					return true;
				}
			} else {
				const parentKey = key;
				const childKeys = this.findChildPermission(sortedPermission[key]);
				const sortedChildKeys = this.sortPermissionBySettingOrders(childKeys)
				for (let i = 0; i < sortedChildKeys.length; i++) {
					const childPagePermission = this.getPermission(sortedChildKeys[i], parentKey);
					if (childPagePermission !== PERMISSION.NONE) {
						page = sortedChildKeys[i];
						break;
					}
				}
			}

			return false;
		});

		return page;
	}

	getFirstPageWithPermission(): string {
		const sortedPermission = this.sortPermissionByPageOrders(PERMISSION_ORDERS);
		return this.findFirstPageWithPermission(sortedPermission);
	}
}
