<div class="dialog-large incident-alert-container">
	<div class="dialog-xlarge__header flex flex-space-between">
		<h4>
			<span class=" incident-{{ data.severity }}">{{ data?.length }}</span> Security Issues
		</h4>
		<a class="text-underline" (click)="dismissAll()" *ngIf="data?.length > 0"> Dismiss All</a>
	</div>

	<div mat-dialog-content class="">
		<ng-container *ngFor="let incident of data">
			<app-inline-notification type="{{ incident.displaySeverity }}" (onClose)="onCloseNotification(incident.id)" [disabled]="permission === PERMISSION.VIEW_ONLY ">
				<div title class="flex flex-space-between">
					<span class="incident-notifications--text description wrap-anywhere"
						><strong>{{ incident.issue }} </strong> detected on {{ incident.productName }} \
						{{ incident.instanceName }}
					</span>
					<span class="incident-notifications--text m-l-sm">{{
						incident.startTime | timezoneDate : 'MMM D, YYYY, h:mm:ss A' || 'N/A'
					}}</span>
				</div>
			</app-inline-notification>
		</ng-container>
	</div>

	<div class="dialog-large__footer">
		<div class="flex flex-end m-t-md">
			<div class="flex">
				<button id="incident-alert-closeBtn" mat-button color="primary" class="m-l-sm" (click)="onCancel()">Close</button>
			</div>
		</div>
	</div>
</div>
