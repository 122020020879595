import { Injectable } from '@angular/core';
import { RequestService } from '@app/shared/services';
import { SETTINGS } from '.';
import { IActiveOnlineKiosk, IReportSetting } from '../model';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class SettingsService {
	constructor(private readonly requestService: RequestService) {}

	public activeKioskLicense(file: File, hashedPin: string) {
		SETTINGS.activeKioskLicense.headers = hashedPin ? { pin: hashedPin } : {};
		const api = JSON.parse(JSON.stringify(SETTINGS.activeKioskLicense));
		const fd = new FormData();
		fd.append('file', file);

		return this.requestService.action(api, fd);
	}

	public activeOnlineKioskLicense(kioskLicense: IActiveOnlineKiosk, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.activeOnlineKioskLicense));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, kioskLicense);
	}

	public getLicenseInfo() {
		return this.requestService.action(SETTINGS.getLicenseInfo);
	}

	public getAccountSetting() {
		return this.requestService.action(SETTINGS.getAccountSetting);
	}

	public getAboutOneCentral() {
		return this.requestService.action(SETTINGS.getAboutOneCentral);
	}

	public saveAccountSetting(userInfo: any, hashedPin: string) {
		const api = JSON.parse(JSON.stringify(SETTINGS.saveAccountSetting));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, userInfo);
	}

	public deactiveKioskLicense(hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.deactiveKioskLicense));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api);
	}
	public revokeCode(hashedPin: string) {
		const api = JSON.parse(JSON.stringify(SETTINGS.revokeCode));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api);
	}

	public getVersion() {
		return this.requestService.action(SETTINGS.getVersion);
	}

	public getServersSetting() {
		return this.requestService.action(SETTINGS.getServersSetting);
	}

	public saveServersSetting(settings: any, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.saveServersSetting));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, settings);
	}

	public getReportsSetting(): Observable<IReportSetting> {
		return this.requestService.action(SETTINGS.getReportsSetting);
	}

	public saveReportsSetting(settings: IReportSetting, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.saveReportsSetting));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, settings);
	}

	public createCertificate(certForm: FormData, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.createCertificate));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, certForm);
	}

	public createCertificateOnTrustedList(certForm: FormData, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.CREATE_CERTIFICATE_ON_TRUSTED_LIST));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, certForm);
	}

	public updateCertificate(certId: string, certData: Record<string, string>, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.updateCertificate));
		api.url = `${api.url}${certId}`;
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, certData);
	}

	public deleteCertificate(certId: string, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.deleteCertificate));
		api.url = `${api.url}${certId}`;
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api);
	}

	public deleteTrustCertificate(certId: string, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.DELETE_TRUST_CERTIFICATE));
		api.url = `${api.url}${certId}`;
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api);
	}

	public getCertificateDetails(certId: string) {
		const api = JSON.parse(JSON.stringify(SETTINGS.getCertificateDetails));
		api.url = `${api.url}${certId}`;
		return this.requestService.action(api);
	}

	public getCertificates(searchCriteria: any) {
		return this.requestService.action(SETTINGS.getCertificates, searchCriteria);
	}

	public getTrustCertificates(searchCriteria: any) {
		return this.requestService.action(SETTINGS.GET_TRUST_CERTIFICATES, searchCriteria);
	}

	public getSSLVerification() {
		return this.requestService.action(SETTINGS.GET_SSL_VERIFICATION);
	}

	public saveSSLVerification(param: any, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.SAVE_CERTIFICATE_VERIFICATION));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, param);
	}

	public getModules() {
		return this.requestService.action(SETTINGS.GET_MODULES);
	}

	public uploadModules(fd: any, hashedPin = '') {
		return this.requestService.action(SETTINGS.UPLOAD_MODULES, fd);
	}

	public deleteModule(id: string, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.DELETE_MODULE));
		api.url = `${api.url}/${id}`;
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api);
	}

	public getServerTimezone() {
		return this.requestService.action(SETTINGS.getServerTimezone);
	}

	public sendTest365Email(email: any, hashedPin = '') {
		const api = JSON.parse(JSON.stringify(SETTINGS.SEND_TEST_365_EMAIL));
		api.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(api, email);
	}
}
