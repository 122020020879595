import { CONSTANT } from "@app/shared/constants";

export const PIN_API = {
	PIN_VERIFICATION: {
		url: `${CONSTANT.URL_PREFIX}pin/check`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {}
	}
};