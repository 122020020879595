<div class="dialog-small__header">
	<h4>Warning</h4>
	<mat-divider></mat-divider>
</div>

<div mat-dialog-content class="dialog-small__content">
	<div class="m-b-lg m-t-md">
		You were removed from the account <span class="text-medium">{{ data.accountName }}</span
		>. Please contact Administrators of that account for help.
	</div>
</div>
<div class="dialog-small__footer">
	<mat-divider></mat-divider>
	<div class="flex flex-end m-t-md">
		<button mat-button color="primary" class="m-l-sm" (click)="onCancel()">OK</button>
	</div>
</div>
