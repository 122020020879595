import { CONSTANT } from '@app/shared/constants';
import { gql } from 'apollo-angular';

export const USER_PROFILE = {
	getUserProfile: {
		url: `${CONSTANT.URL_PREFIX}users/profile`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	saveUserProfile: {
		url: `${CONSTANT.URL_PREFIX}users/profile`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
	getAccountList: {
		url: `${CONSTANT.URL_PREFIX}users/accounts`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	switchAccount: {
		url: `${CONSTANT.URL_PREFIX}users/switch-account`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
};

export const UPDATE_ORGANIZATION: any = gql`
	mutation UpdateOrganization($id: UUID!) {
		organizationUpdateUsing(id: $id) {
			success
			errors
		}
	}
`;

export const ORG_LIST = gql`
	query {
		organizations {
			id
			name
			ssoId
		}
	}
`;

export const DX_PROFILE = gql`
	query {
		profile {
			id
			firstName
			lastName
			title
			email
			userTypes
			country
			timezone
			usingOrganizationId
			currentOrganizationId
			platform
			productManagement
			currentOrganizationName
			currentOrganizationAccountId
			sfdcContactId
			chatbotEnable
			mdEmailSecurityUrl
			sfdcData
			maUrls
		}
	}
`;
