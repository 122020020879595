import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SECURITY_INCIDENT, SEVERITY_SETTING } from '../../constants/incident.constant';

@Component({
	selector: 'app-security-alert-setting',
	templateUrl: './security-alert-setting.component.html',
	styleUrls: ['./security-alert-setting.component.scss'],
})
export class SecurityAlertSettingComponent {
	alertSetting = SEVERITY_SETTING;
	severityName = SECURITY_INCIDENT;
	columns = ['Incident', 'Alert Severity', 'Show Notification'];
	isLoading = false;
	constructor(public dialogRef: MatDialogRef<SecurityAlertSettingComponent>) {}

	onCancel() {
		this.dialogRef.close();
	}
}
