export enum USER_STATUS {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	INVITED = 'Invited',
}

export const USER_STATUS_FIELD = 'status';
export const USER_ROLE_FIELD = 'role';
export const USER_CUSTOM_ROLE_FIELD = 'customRole';
export const USER_ROLEIDS_FIELD = 'roleIds';

export const CUSTOM_USER_ROLE_ID = 'custom-role';

export enum USER_ACTION {
	RESET_PIN = 'reset-pin',
	RESET_PASSWORD = 'reset-password',
	UPDATE_ROLE = 'update-role',
	DELETE = 'delete',
	EXPORT_CSV = 'export-csv',
}

export const MAXIMUM_INVITATION = 10;

export const USER_MANAGEMENT = {
	ACTION: [
		{ value: USER_ACTION.UPDATE_ROLE, viewValue: 'Update Role', disabled: false },
		{ value: USER_ACTION.DELETE, viewValue: 'Delete', disabled: false },
	],
	CONSOLE_USER: 'A user can log into My OPSWAT console to manage this account.',
};

export enum DIRECTORY_TYPE_LABEL {
	LOCAL = 'Local',
	AD = 'Active Directory',
	SAML = 'Single Sign-On',
}

export enum USER_TYPE_LABEL {
	USER = 'User',
	GROUP = 'Group',
	SAML_GROUP = 'Group',
	AD_GROUP = 'Group',
	AD_USER = 'User',
	SAML_USER = 'User',
}

export enum USER_TYPE {
	USER = 'USER',
	GROUP = 'GROUP',
}
