import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-user-removed-switch-account-dialog',
	templateUrl: './user-removed-switch-account.component.html',
})
export class UserRemovedSwitchAccountDialogComponent {

	constructor(
		public dialogRef: MatDialogRef<UserRemovedSwitchAccountDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	onCancel() {
		this.dialogRef.close(false);
	}
}
