import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';

export class Publisher {
	// Auth
	currentUser: ReplaySubject<any>;
	aboutCentral: BehaviorSubject<any>;
	tabItems: BehaviorSubject<any>;
	isCompletedLoadingProduct: Subject<any>;
	lastUpdated: BehaviorSubject<any>;
	reload: BehaviorSubject<any>;
	isShowNoProduct: BehaviorSubject<any>;
	kioskDashboardEdit: Subject<any>;
	firewallDashboardEvent: Subject<any>;
	mediaFirewallReload: Subject<any>;
	mftDashboardReload: Subject<any>;
	mdicapDashboardReload: Subject<any>;
	mediaFirewallLastUpdated: Subject<any>;
	isShowMediaFirewallDashboardIcon: Subject<any>;
	isShowKioskDashboardIcon: Subject<any>;
	updateSecurityIncident: BehaviorSubject<any>;
	hasSecurityIncident: ReplaySubject<any>;
	securityIncidents: BehaviorSubject<any>;
	dxProfile: BehaviorSubject<any>;
	accountInfo: ReplaySubject<any>;
	mdCoreLastUpdated: Subject<any>;
	isAllNotDisplay: Subject<any>;
	permission: ReplaySubject<any>;
	cancelPreviousPermissionCall = new Subject<void>();

	constructor() {
		this.currentUser = new ReplaySubject(1);
		this.aboutCentral = new BehaviorSubject(null);
		this.tabItems = new BehaviorSubject([]);
		this.isCompletedLoadingProduct = new Subject();
		this.lastUpdated = new BehaviorSubject(null);
		this.reload = new BehaviorSubject(false);
		this.isShowNoProduct = new BehaviorSubject(false);
		this.mediaFirewallLastUpdated = new Subject();
		this.mediaFirewallReload = new Subject();
		this.mftDashboardReload = new Subject();
		this.mdicapDashboardReload = new Subject();
		this.kioskDashboardEdit = new Subject();
		this.firewallDashboardEvent = new Subject();
		this.isShowMediaFirewallDashboardIcon = new Subject();
		this.isShowKioskDashboardIcon = new Subject();
		this.updateSecurityIncident = new BehaviorSubject(false);
		this.hasSecurityIncident = new ReplaySubject(1);
		this.securityIncidents = new BehaviorSubject([]);
		this.dxProfile = new BehaviorSubject({});
		this.accountInfo = new ReplaySubject();
		this.mdCoreLastUpdated = new BehaviorSubject(null);
		this.isAllNotDisplay = new Subject();
		this.permission = new ReplaySubject();
	}
}
