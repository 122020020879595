import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { REMOVED_USER_ERROR } from '@app/shared/constants';
import { AuthService } from '@app/shared/services';

@Component({
	selector: 'app-user-removed-dialog',
	templateUrl: './user-removed-dialog.component.html',
})
export class UserRemovedDialogComponent {
	removedError = REMOVED_USER_ERROR;
	constructor(
		public dialogRef: MatDialogRef<UserRemovedDialogComponent>,
		private readonly authService: AuthService,
		private readonly router: Router,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {}

	// Should call sso directly becasue logout api may validate the removed user token
	terminate() {
		this.dialogRef.close();
		this.authService.terminateSession();
	}
}
