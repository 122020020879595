export const MOCK_SEARCH_DIRECTORY_LIST = [
	{
		id: 'xxxxxxxxxxxxxxxxxxxxxxxx',
		name: 'Default',
		description: 'Default',
		type: 'LOCAL',
		enabled: true,
		order: 0,
	},
	{
		id: 'yyyyyyyyyyyyyyyyyyyy',
		name: 'batman',
		description: 'batdirectory',
		type: 'LOCAL',
		enabled: false,
		order: 2,
	},
];

export const MOCK_DELETE_DIRECTORY = {
	successIds: ['xxxxxxxxxxxxxxxxxxxxxx'],
};

export const MOCK_CHANGE_DIRECTORY_STATUS = {
	directory: {
		id: 'xxxxxxxxxxxxxxxx',
		accountId: 'yyyyyyyyyyyyyyyyy',
		name: 'test',
		type: 'LOCAL_USER',
		enabled: true,
		order: 0,
		passPolicy: {
			lockoutEnabled: false,
			recoveryQuestion: false,
		},
		timeout: 0,
	},
	success: true,
};

export const MOCK_PARSE_METADATA = {
	issuer: 'https://sts.windows.net/xxxxxxxxxxxxxxxxxxxxxxxx/',
	loginUrl: 'https://login.microsoftonline.com/xxxxxxxxxxxxxxxxxxxxxxxx/saml2',
	logoutUrl: 'https://login.microsoftonline.com/xxxxxxxxxxxxxxxxxxxxxxxx/saml2',
	cert: 'temporary certificate',
	certInfo: 'CN=Microsoft Azure Federated SSO Certificate, SigAlg=SHA256withRSA, Expiration=08 Jan 2027 10:37:25 PST',
};

export const MOCK_PARSE_CERTIFICATE = {
	cert: 'temporary certificate',
	certInfo: 'CN=MyOpswat, SigAlg=SHA256withRSA, Expiration=03 Jan 2025 14:39:16 PST',
};

export const MOCK_ADD_SSO_DIRECTORY = {
	directory: {
		id: 'xxxxxxxxxxxxxxxxxxx',
		accountId: 'yyyyyyyyyyyyyyyyyyyyyyyyyyy',
		name: 'okta',
		description: 'okta',
		type: 'SAML',
		enabled: true,
		order: 0,
		issuer: 'http://www.test.com/test',
		idpCertInfo: 'E',
		acsUrl: '/api/console/login/saml2/sso/zzzzzzzzzzzzz',
		loginUrl: '',
		logoutUrl: '',
		errorUrl: '',
		groupAttribute: 'group',
		defaultRole: 'aaaaaaaaaaaaaaaaaaaaa',
		recordIdentity: true,
		signingCert: '',
	},
	success: true,
};

export const MOCK_GET_LIST_IDP = [
	{
		id: 'xxxxxxxxx',
		accountId: 'yyyyyyyyyy',
		ocmGroupName: 'test 123',
		idpGroupName: 'Test',
		role: 'zzzzzzzzzzzz',
	},
	{
		id: 'mmmmmmmmmmmmmm',
		accountId: 'kkkkkkkkkkkkk',
		ocmGroupName: 'test',
		idpGroupName: 'test',
		role: 'qqqqqqqqqqqqqqqqq',
	},
];

export const MOCK_GET_DIRECTORY_DETAIL = {
	id: 'xxxxxx',
	accountId: 'yyyyyyyyyyy',
	name: 'okta',
	description: 'description okta',
	type: 'SAML',
	enabled: true,
	order: 0,
	issuer: 'tester',
	idpCertInfo: 'idp cert info ',
	acsUrl: '/api/console/login/saml2/sso/zzzzzzzzzzzz',
	loginUrl: 'https://test/login-url',
	logoutUrl: 'https://test/logout-url',
	errorUrl: '',
	groupAttribute: 'group',
	defaultRole: 'kkkkkkkkkkkkkkkk',
	recordIdentity: true,
	roleId: 'aaaaaaa',
	signingCert: 'dGVzdA==',
};

export const MOCK_UPDATE_DIRECTORY_DETAIL = {
	name: 'okta',
	description: 'okta',
	type: 'SAML',
	enabled: true,
	issuer: '',
	loginUrl: '',
	logoutUrl: '',
	errorUrl: '',
	groupAttribute: 'group',
	recordIdentity: true,
	defaultRole: 'xxxxxxxxxxxx',
	id: 'yyyyyyyyyyyyyyyyyy',
	cert: '',
};

export const MOCK_LIST_DIRECTORY_FOR_LOGIN = [
	{
		id: 'xxxxxxxxxxxxxxxxxxx',
		accountId: 'yyyyyyyyyyyyyyyyyyy',
		name: 'Default',
		description: 'Default',
		type: 'LOCAL',
		enabled: true,
		order: 0,
		passPolicy: {
			lockoutEnabled: false,
			recoveryQuestion: false,
		},
		timeout: 0,
	},
	{
		id: 'zzzzzzzzzzzzzzzz',
		accountId: 'aaaaaaaaaaaaaaaaa',
		name: 'okta',
		description: 'okta',
		type: 'SAML',
		enabled: true,
		order: 0,
		issuer: '',
		idpCertInfo: '',
		acsUrl: '/api/console/login/saml2/sso/bbbbbbbbbbbbbbbbbb',
		loginUrl: '',
		logoutUrl: '',
		errorUrl: '',
		groupAttribute: 'group',
		defaultRole: 'ccccccccccccccccccccccc',
		recordIdentity: true,
	},
];
