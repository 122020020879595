import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UnsavedChangesGuard } from '@app/core/guards';
import { OverviewGuard } from '@app/core/guards/overview.guard';
import { PermissionDeniedPageComponent } from '@app/shared/components/permission-denied-page/permission-denied-page.component';
import { DEFAULT_INVENTORY_PERMISSION, OPSWAT_PRODUCTS, PERMISSION_URL } from '@app/shared/constants';
import { InventoryOverviewComponent } from './inventory-overview/inventory-overview.component';

const kioskModule = () => import('./metadefender-kiosk/metadefender-kiosk.module').then((m) => m.MetadefenderKioskModule);
const productModule = () => import('./metadefender-product/metadefender-product.module').then((m) => m.MetadefenderProductModule);
export const routes: Routes = [
	{
		path: '',
		component: InventoryOverviewComponent,
		canActivate: [UnsavedChangesGuard],
		pathMatch: 'full',
	},
	{
		path: 'metadefender-kiosk',

		children: [
			{
				path: '',
				redirectTo: 'overview',
				pathMatch: 'full',
			},
			{
				path: 'l-series',
				loadChildren: kioskModule,
				data: {
					productType: OPSWAT_PRODUCTS.MK5.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MK5.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'k-series',
				loadChildren: kioskModule,
				data: {
					productType: OPSWAT_PRODUCTS.KIOSK.TYPE,
					permissionKey: OPSWAT_PRODUCTS.KIOSK.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'overview',
				canActivate: [OverviewGuard, UnsavedChangesGuard],
				loadChildren: () => import('./metadefender-kiosk/overview/overview.module').then((m) => m.OverviewModule),
			},

			{ path: `l-series/${PERMISSION_URL.PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `l-series/${PERMISSION_URL.INSTANCE_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `l-series/${PERMISSION_URL.GROUP_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `l-series/${PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `l-series/${PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },

			{ path: `k-series/${PERMISSION_URL.PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `k-series/${PERMISSION_URL.INSTANCE_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `k-series/${PERMISSION_URL.GROUP_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `k-series/${PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
			{ path: `k-series/${PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED}`, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'metadefender-core',
		children: [
			{
				path: '',
				loadChildren: () => import('./metadefender-core/metadefender-core.module').then((m) => m.MetadefenderCoreModule),
				data: {
					productType: OPSWAT_PRODUCTS.MDCORE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDCORE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'metadefender-drive',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.MDD.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDD.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'metadefender-media-firewall',
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./metadefender-media-firewall/metadefender-media-firewall.module').then(
						(m) => m.MetadefenderMediaFirewallModule
					),
				data: {
					productType: OPSWAT_PRODUCTS.UF3.TYPE,
					permissionKey: OPSWAT_PRODUCTS.UF3.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'metadefender-icap',
		children: [
			{
				path: '',
				loadChildren: () => import('./metadefender-icap/metadefender-icap.module').then((m) => m.MetadefenderIcapModule),
				data: {
					productType: OPSWAT_PRODUCTS.MDICAP.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDICAP.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'metadefender-vault',
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./metadefender-managed-file-transfer/metadefender-managed-file-transfer.module').then(
						(m) => m.MetadefenderManagedFileTransferModule
					),
				data: {
					productType: OPSWAT_PRODUCTS.VAULT.TYPE,
					permissionKey: OPSWAT_PRODUCTS.VAULT.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'filescan-sandbox',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.FSCAN.TYPE,
					permissionKey: OPSWAT_PRODUCTS.FSCAN.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'neuralyzer',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NEURALYZER.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NEURALYZER.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'otfuse',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.OTFUSE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.OTFUSE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'mdss',
		children: [
			{
				path: '',
				loadChildren: () =>
					import('./metadefender-storage-security/metadefender-storage-security.module').then(
						(m) => m.MetadefenderStorageSecurityModule
					),
				data: {
					productType: OPSWAT_PRODUCTS.MDSS.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDSS.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},

	{
		path: 'mdemail',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.MDEMAIL.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDEMAIL.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'netwall',
		children: [
			// Unidirectional
			{
				path: 'unidirectional-security-gateway-blue',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWUSGBLUE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWUSGBLUE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'unidirectional-security-gateway-red',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWUSGRED.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWUSGRED.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			// Bilateral
			{
				path: 'bilateral-security-gateway-blue',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWBSGBLUE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWBSGBLUE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'bilateral-security-gateway-red',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWBSGRED.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWBSGRED.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			// Threat Protection
			{
				path: 'threat-protection-blue',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWTPBLUE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWTPBLUE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'threat-protection-red',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWTPRED.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWTPRED.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},

			// Optical Diode
			{
				path: 'optical-diode-blue',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWODBLUE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWODBLUE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{
				path: 'optical-diode-red',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWODRED.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWODRED.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			// Transfer Guard
			{
				path: 'transfer-guard-blue',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWTGBLUE.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWTGBLUE.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
			},
			{
				path: 'transfer-guard-red',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.NWTGRED.TYPE,
					permissionKey: OPSWAT_PRODUCTS.NWTGRED.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
			},
		],
	},
	// {
	// 	path: 'netwall/unidirectional-security-gateway-blue',
	// 	children: [
	// 		{
	// 			path: '',
	// 			loadChildren: () => import('./metadefender-product/metadefender-product.module').then((m) => m.MetadefenderProductModule),
	// 			data: {
	// 				productType: OPSWAT_PRODUCTS.NWUSGBLUE.TYPE,
	// 				permissionKey: OPSWAT_PRODUCTS.NWUSGBLUE.KEY,
	// 				permission: DEFAULT_INVENTORY_PERMISSION,
	// 			},
	// 			 // canActivate: [PermissionProductGuard],
	// 		},

	// 		{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
	// 		{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
	// 		{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
	// 		{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
	// 		{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
	// 	],
	// },
	{
		path: 'supply-chain',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.MDSSC.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDSSC.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
				// canActivate: [PermissionProductGuard],
			},
			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	},
	{
		path: 'industrial-firewall',
		children: [
			{
				path: '',
				loadChildren: productModule,
				data: {
					productType: OPSWAT_PRODUCTS.MDIF.TYPE,
					permissionKey: OPSWAT_PRODUCTS.MDIF.KEY,
					permission: DEFAULT_INVENTORY_PERMISSION,
				},
			},
			{ path: PERMISSION_URL.PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.GROUP_DETAIL_INSTANCE_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
			{ path: PERMISSION_URL.NEW_GROUP_PERMISSION_DENIED, component: PermissionDeniedPageComponent },
		],
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule],
})
export class InventoryRoutingModule {}
