import { Injectable, OnDestroy } from '@angular/core';
import { Observable, timer, Subject } from 'rxjs';
import { share, takeUntil, delayWhen, retryWhen, repeatWhen, tap } from 'rxjs/operators';
import { INotifications } from '@app/shared/models';
import { RequestService } from '..';
import { NOTIFICATION_API } from './notification.api';
import { POLLING_TIMECONFIG, MAXIMUM_NOTI_ATTEMPTS, HTTP_ERROR } from '@app/shared/constants';

@Injectable({
	providedIn: 'root',
})
export class NotificationService implements OnDestroy {
	public allNotificationPoll$: Observable<INotifications>;
	public readonly stopPolling = new Subject();
	private pollingDurationInSec = POLLING_TIMECONFIG.POLLING_INTERVAL_IN_SEC;
	public notiAttempts = 0;
	public notiTypes = ['general', 'security_incident'];
	constructor(private readonly requestService: RequestService) {
		const api = this.updatePollingAPI(0);
		this.allNotificationPoll$ = this.requestService.action(api).pipe(
			repeatWhen((reponse$) => {
				this.notiAttempts = 0;
				return reponse$.pipe(delayWhen((val) => timer(POLLING_TIMECONFIG.RETRY_AFTER)));
			}),
			retryWhen((error$) => {
				return error$.pipe(
					tap((error) => {
						this.checkForNotiAttemps(error);
					}),
					delayWhen((val) => {
						return timer(POLLING_TIMECONFIG.RETRY_AFTER);
					})
				);
			}),
			share(),
			takeUntil(this.stopPolling)
		);
	}

	ngOnDestroy() {
		this.stopPolling.next();
	}

	stopNotification() {
		this.stopPolling.next();
	}

	getAllNotifications(): Observable<INotifications> {
		const api = this.updatePollingAPI(0);
		return this.requestService.action(api).pipe(
			retryWhen((error$) => {
				return error$.pipe(
					tap((error) => {
						this.checkForNotiAttemps(error);
					}),
					delayWhen((val) => timer(POLLING_TIMECONFIG.RETRY_AFTER))
				);
			}),
			share(),
			takeUntil(this.stopPolling)
		);
	}

	getNotifications(types: string[] = this.notiTypes) {
		const api = this.updatePollingAPI(0, types);
		return this.requestService.action(api);
	}

	getNewNotification() {
		return this.requestService.action(NOTIFICATION_API.new_notifications);
	}

	getAllNotificationPoll(): Observable<INotifications> {
		return this.allNotificationPoll$;
	}

	readNotifications() {
		return this.requestService.action(NOTIFICATION_API.read_notifications);
	}

	checkMessage(msgId: string[]) {
		return this.requestService.action(NOTIFICATION_API.check_message, msgId);
	}

	set pollingInterval(interval: number) {
		this.pollingDurationInSec = interval;
	}

	get pollingInterval() {
		return this.pollingDurationInSec;
	}

	private updatePollingAPI(interval: number, types: string[] = this.notiTypes) {
		const pollingApi = JSON.parse(JSON.stringify(NOTIFICATION_API.get_notifications));
		const requestParams: any = {};
		if (interval && interval !== 0) {
			requestParams.interval = interval;
		}
		requestParams.types = types.toString();
		pollingApi.params = requestParams;

		return pollingApi;
	}

	public checkForNotiAttemps(error: any) {
		if (error.status === HTTP_ERROR.UNAUTHORIZED) {
			this.notiAttempts++;
		}
		if (this.notiAttempts >= MAXIMUM_NOTI_ATTEMPTS) {
			this.stopNotification();
		}
	}
}
