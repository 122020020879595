import { Injectable } from '@angular/core';
import { IProductStatistic } from '../../models';
import { RequestService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { INVENTORY_OVERVIEW } from './inventory-overview.api';
import { IIncidentByProduct, IIncidentDetail } from '../../models/incident.model';

@Injectable({
	providedIn: 'root',
})
export class InventoryOverviewService {
	constructor(readonly requestService: RequestService) {}

	getOverviewInstance(): Observable<IProductStatistic[]> {
		return this.requestService.action(INVENTORY_OVERVIEW.GET_INVENTORY_STATISTIC);
	}

	getOverviewIncident(searchCriteria: any): Observable<IIncidentByProduct[]> {
		return this.requestService.action(INVENTORY_OVERVIEW.GET_INVENTORY_INCIDENT, searchCriteria);
	}

	getIncidentDetail(searchCriteria: any): Observable<IIncidentDetail> {
		return this.requestService.action(INVENTORY_OVERVIEW.GET_INVENTORY_INCIDENT_DETAIL, searchCriteria);
	}
}
