import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PAGE_TITLE } from '@app/shared/constants/page-title.constant';

@Component({
	selector: 'app-permission-denied-page',
	templateUrl: './permission-denied-page.component.html',
	styleUrls: ['./permission-denied-page.component.scss'],
})
export class PermissionDeniedPageComponent {
	breadcrumbItems = [{ title: 'Permission Denied' }];

	constructor(public titleService: Title) {
		setTimeout(() => {
			this.titleService.setTitle(`${PAGE_TITLE.PERMISSION_DENIED}`);
		}, 0);
	}
}
