import { DASHBOARD_COLORS } from '../constants';

export const crosshairs = {
	show: true,
	width: 5,
	position: 'front',
	opacity: 1,
	stroke: {
		color: 'white',
		width: 2,
		dashArray: 0,
	},
	fill: {
		type: 'solid',
		color: DASHBOARD_COLORS.BLUE,
	},
	dropShadow: {
		enabled: false,
		top: 0,
		left: 0,
		blur: 1,
		opacity: 0.4,
	},
};
