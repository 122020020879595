import { CONSTANT } from '@app/shared/constants';
import { IS_NEW, NOTIFICATION_LIST } from './notification.mock';

export const NOTIFICATION_API = {
	get_notifications: {
		url: `${CONSTANT.URL_PREFIX}notifications`,
		mock: NOTIFICATION_LIST,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: false,
	},
	read_notifications: {
		url: `${CONSTANT.URL_PREFIX}notifications`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: false,
	},
	check_message: {
		url: `${CONSTANT.URL_PREFIX}notifications`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: false,
	},
	new_notifications: {
		url: `${CONSTANT.URL_PREFIX}notifications/incoming`,
		mock: IS_NEW,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: false,
	},
};
