export const environment = {
	version: '8.0.0',
	production: true,
	isHttps: false,
	jwtEncBase64Key: 'VkhWaGJqRXlNelExTmpjNA==',
	vaultAPIServer: 'https://portalsft.opswat.com',
	ocmAPIServer: '',
	productUrl: '',
	ssoLoginUrl: '',
	ssoLogoutUrl: '',
	ssoChangePasswordUrl: '',
	myOpswat: '/',
	myOpswatEndpoint: '',
	myOpswatAuthen: false,
	onCloud: false,
	engineStatusUrl: 'https://enginestatus.dl.opswat.com/avengines/',
	environment: 'on-prem',
	chatbotConfig: {
		CHAT_BOT_INJECT_URL_1: '',
		CHAT_BOT_INJECT_URL_2: '',
		CHAT_BOT_HEADER_URL_CUSTOM: '',
	},
};
