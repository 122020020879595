import dayjs from 'dayjs';
import { dateHelper } from '@app/shared/utils';
const ABB_LENGTH = 5; // Length condition to start abbreviate

export const mapAllProducts = [
	{
		key: 'filetypeTime',
		value: 'Filetype',
	},
	{
		key: 'extractionTime',
		value: 'Archive extraction',
	},
	{
		key: 'cdrTime',
		value: 'Deep CDR',
	},
	{
		key: 'dlpTime',
		value: 'Proactive DLP',
	},
	{
		key: 'vulTime',
		value: 'Vulnerability assessment',
	},
	{
		key: 'sbomTime',
		value: 'SBOM',
	},
	{
		key: 'opswatfilescanTime',
		value: 'Adaptive Sandbox',
	},
	{
		key: 'yaraTime',
		value: 'Yara',
	},
	{
		key: 'cloudLookupTime',
		value: 'Cloud lookup',
	},
	{
		key: 'decryptedTime',
		value: 'Decryption',
	},
	{
		key: 'externalScanTime',
		value: 'External scanner',
	},
	{
		key: 'avScanTime',
		value: 'Metascan™',
	},
	{
		key: 'compressionTime',
		value: 'Archive compression',
	},
	{
		key: 'reputationTime',
		value: 'Reputation',
	},
	{
		key: 'waitChildFilesTime',
		value: 'Nested files',
	},
	{
		key: 'othersTime',
		value: 'Others',
	},
	{
		key: 'postActionTime',
		value: 'Post action',
	},
	{
		key: 'quarantineStorageTime',
		value: 'Quarantine storage',
	},
	{
		key: 'dlpStorageTime',
		value: 'Proactive DLP storage',
	},
	{
		key: 'cdrStorageTime',
		value: 'Deep CDR storage',
	},
	{
		key: 'cdrWaitTime',
		value: 'CDR waiting time',
	},
	{
		key: 'cooTime',
		value: 'Country of Origin',
	},
	{
		key: 'avWaitTime',
		value: 'Metascan™ waiting time',
	},
	{
		key: 'cdrCompressionTime',
		value: 'CDR Compression',
	},
	{
		key: 'dlpCompressionTime',
		value: 'DLP Compression',
	},
	{
		key: 'waitTime',
		value: 'Wait Time',
	},
];

export enum FILTER_ENUM {
	ONEDAY = '24H',
	SEVENDAYS = '7D',
	ONEMONTH = '30D',
	ONEYEAR = '12M',
	CUSTOM = 'CUSTOM',
}

export const abbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return value.toString();
	}

	const suffixes = ['', 'K', 'M', 'B', 'T', 'Qd', 'Qn', 'Sx', 'Sp', 'Oc', 'N', 'Dc'];

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	if (value < 1 && value > 0) {
		return `${value.toFixed(1)}`;
	} else {
		const numberBeforeSuffixes = parseFloat((value / Math.pow(1000, indexSuffixes)).toFixed(1));

		let result = numberBeforeSuffixes.toString();

		if (numberBeforeSuffixes === 1000) {
			result = '1';
			indexSuffixes++;
		}

		if (suffixes[indexSuffixes]) {
			result += `${suffixes[indexSuffixes]}`;
		}

		return result;
	}
};

export const isAbbreviateNumber = function (value: number) {
	if (value === undefined) {
		return '0';
	}

	if (value.toString().length < ABB_LENGTH) {
		return true;
	}

	let indexSuffixes = 0;
	if (value === 0) {
		indexSuffixes = value;
	} else {
		indexSuffixes = Math.floor(Math.log(value) / Math.log(1000));
	}

	return indexSuffixes === 0;
};

export const numberWithCommas = (number: number) => {
	return number.toLocaleString();
};

export const startTimeBaseOnFilter = (currentFilter: any, startTime: any, amount: number) => {
	let startTimeString = '';
	if (currentFilter === FILTER_ENUM.ONEDAY) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('h A, DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.ONEMONTH || currentFilter === FILTER_ENUM.SEVENDAYS) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else if (currentFilter === FILTER_ENUM.CUSTOM && amount <= 90) {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('DD MMM, YYYY');
	} else {
		startTimeString = dayjs(startTime).tz(dateHelper.getTimezone()).format('MMM, YYYY');
	}
	return startTimeString;
};

export const dataBaseOnFilter = (currentFilter: any) => {
	const dataZero: number[] = [];
	const filter = {
		[FILTER_ENUM.ONEDAY]: 24,
		[FILTER_ENUM.SEVENDAYS]: 7,
		[FILTER_ENUM.ONEMONTH]: 30,
		[FILTER_ENUM.ONEYEAR]: 12,
		[FILTER_ENUM.CUSTOM]: 'CUSTOM',
	};

	for (let index = 0; index < filter[currentFilter]; index++) {
		dataZero.push(0);
	}

	return dataZero;
};

export function findNextMultipleOfFour(num: number) {
	if (num < 20) {
		const remainder = num % 4;
		if (remainder === 0) {
			return num + 4;
		} else {
			return num + (4 - remainder);
		}
	}
	let multipleOfFour;
	const numberLength = num.toString().length;
	if (numberLength <= 3) {
		multipleOfFour = 20;
	} else if (numberLength <= 4) {
		multipleOfFour = 100;
	} else {
		multipleOfFour = 1000;
	}
	num = Math.floor((num + multipleOfFour - 1) / multipleOfFour) * multipleOfFour;

	return num;
}

export function convertToBytes(value: number, unit: string) {
	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const unitIndex = units.indexOf(unit);
	if (unitIndex === -1) {
		throw new Error('Invalid unit');
	}
	return value * Math.pow(1024, unitIndex);
}

export function findNextMultipleOfFourForVolume(sizeInBytes: number) {
	try {
		if (sizeInBytes > 0) {
			const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
			let power = Math.round(Math.log(sizeInBytes) / Math.log(1024));
			power = Math.min(power, units.length - 1);

			let size = sizeInBytes / Math.pow(1024, power);
			if (power >= 2 && size < 0.5) {
				power -= 1;
				size = sizeInBytes / Math.pow(1024, power);
			}
			const formattedSize = Math.round(size * 100) / 100; // keep up to 2 decimals
			const unit = units[power];
			const multipleOfFour = findNextMultipleOfFour(Math.ceil(formattedSize));
			return convertToBytes(multipleOfFour, unit);
		} else {
			return findNextMultipleOfFour(sizeInBytes);
		}
	} catch (e) {
		const lcm = 10240; // LCM of 4, 10 and 1024
		return Math.ceil(sizeInBytes / lcm) * lcm;
	}
}

export const minWidthLabel = (number: number) => {
	if (number === 0) {
		return 10;
	}
	let length = abbreviateNumber(number / 4).length;
	for (let i = number / 2; i <= number; i += number / 4) {
		if (length < abbreviateNumber(i).length) {
			length = abbreviateNumber(i).length;
		}
	}
	const width = {
		1: 10,
		2: 15,
		3: 20,
		4: 25,
		5: 30,
		6: 35,
		7: 40,
		8: 45,
		9: 50,
	};

	return width[length];
};

export const xAxisFormatter = function (currentFilter: any, processingOverviewFigureLabels: any, value: any) {
	if (currentFilter === FILTER_ENUM.ONEDAY) {
		const currentIndex = processingOverviewFigureLabels.indexOf(parseInt(value));
		const previousIndex = currentIndex - 1;
		const currentDate = dayjs(processingOverviewFigureLabels[currentIndex]).tz(dateHelper.getTimezone());
		const previousDate = dayjs(processingOverviewFigureLabels[previousIndex]).tz(dateHelper.getTimezone());

		if (previousDate && !currentDate.isSame(previousDate, 'day')) {
			return [
				currentDate.tz(dateHelper.getTimezone()).format('h A'),
				currentDate.tz(dateHelper.getTimezone()).format('DD MMM'),
				currentDate.tz(dateHelper.getTimezone()).format('YYYY'),
			];
		}
		return [currentDate.tz(dateHelper.getTimezone()).format('h A')];
	} else if (currentFilter === FILTER_ENUM.ONEMONTH || currentFilter === FILTER_ENUM.SEVENDAYS) {
		return [dayjs(value).tz(dateHelper.getTimezone()).format('DD MMM'), dayjs(value).tz(dateHelper.getTimezone()).format('YYYY')];
	} else {
		const firstDate = new Date(processingOverviewFigureLabels[0]);
		const lastDate = new Date(processingOverviewFigureLabels[processingOverviewFigureLabels.length - 1]);
		if (firstDate.getFullYear() === lastDate.getFullYear() && firstDate.getMonth() === lastDate.getMonth()) {
			return [dayjs(value).tz(dateHelper.getTimezone()).format('DD MMM'), dayjs(value).tz(dateHelper.getTimezone()).format('YYYY')];
		}
		return [dayjs(value).tz(dateHelper.getTimezone()).format('MMM'), dayjs(value).tz(dateHelper.getTimezone()).format('YYYY')];
	}
};

export const endTruncate = (value: string, maxLength: number) => {
	if (!value) {
		return '-';
	}
	if (value.length <= maxLength) {
		return value;
	}
	return value.slice(0, maxLength) + '...';
};

export const formatDataByStorageUnit = (sizeInBytes: number) => {
	if (sizeInBytes === 0) {
		return '0 B';
	}

	const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let power = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
	power = Math.min(power, units.length - 1);

	const size = sizeInBytes / Math.pow(1024, power);
	if (power === 0) {
		return `${sizeInBytes} B`;
	}

	const formattedSize = size.toFixed(2);
	const unit = units[power];
	return `${formattedSize} ${unit}`;
};
