export enum DIALOG_WIDTH {
	SMALL = '350px',
	MEDIUM = '550px',
	LARGE = '700px',
	EXTRA_LARGE = '1000px',
}

export enum DIALOG_HEIGHT {
	SMALL = '300px',
	MEDIUM = '480px',
	LARGE = '480px',
	EXTRA_LARGE = '800px',
}

export const CUSTOM_DIALOG_SIZE = {
	ACTIVATE_LICENSE: {
		WIDTH: '350px',
		HEIGHT: '540px',
	},
	ABOUT_MO: {
		WIDTH: '820px',
	},
};

export const DIALOG_PANEL_CLASS = {
	PADDING_NONE: 'dialog-padding-none',
};
