import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { URL_CONSTANT } from '@app/shared/constants';
import { environment } from '@env/environment';
import { SHA256, enc } from 'crypto-js';
import { Observable } from 'rxjs';
import { RequestService } from '../request.service';
import { PIN_API } from './pin.api';

@Injectable({
	providedIn: 'root',
})
export class PinService {
	private pinEnable = false;
	public pin = '';
	public status = 200;
	public error = '';
	public invalidPin = false;
	constructor(public readonly router: Router, private readonly requestService: RequestService) { }

	get pinValidation() {
		return this.pinEnable;
	}

	set pinValidation(enable: boolean) {
		this.pinEnable = enable;
	}

	get pinInvalid() {
		return this.invalidPin;
	}

	set pinInvalid(status: boolean) {
		this.invalidPin = status;
	}

	get pinError() {
		return this.error;
	}

	set pinError(error: string) {
		this.error = error;
	}

	get pinState() {
		return this.pin;
	}

	set pinState(pin: string) {
		this.pin = pin;
	}

	get pinStatus() {
		return this.status;
	}

	set pinStatus(status: number) {
		this.status = status;
	}

	resetPinStates() {
		this.invalidPin = false;
		this.error = '';
		this.pin = '';
		this.status = 200;
	}

	forkJoinApisWithPin(apis: any[], apiPayloads: any[]) {
		const hashedPin = this.pin ? this.hashPin(this.pin) : '';
		const observables: Observable<any>[] = [];

		apis.forEach((api, index) => {
			if (apiPayloads[index]) {
				const obs = api(apiPayloads[index], hashedPin);
				observables.push(obs);
			} else {
				observables.push(api(hashedPin));
			}
		});
		return observables;
	}

	hashPin(pin: string) {
		return SHA256(pin).toString(enc.Hex);
	}

	redirectToUserProfile() {
		if (environment.onCloud) {
			window.open(environment.ssoChangePasswordUrl, '_blank');
		} else {
			window.open(URL_CONSTANT.USER_PROFILE, '_blank');
		}
	}

	PINVerification(hashedPin: string): Observable<any> {
		const api = JSON.parse(JSON.stringify(PIN_API.PIN_VERIFICATION));
		api.headers = { pin: hashedPin };
		return this.requestService.action(api);
	}
}
