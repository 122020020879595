import { CONSTANT } from '@app/shared/constants';

/* eslint-disable */
export const INVENTORY_INSTANCE = {
	GET_INSTANCE_LIST: {
		url: `${CONSTANT.URL_PREFIX}instance/search`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_ALL_GROUPS: {
		url: `${CONSTANT.URL_PREFIX}instance/groups`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_INSTANCE_DETAIL: {
		url: `${CONSTANT.URL_PREFIX}instance/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
	},
	DELETE_INSTANCES: {
		url: `${CONSTANT.URL_PREFIX}instance`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.DELETE,
		use_mock: false,
		loading: true,
		headers: {},
	},
	UPDATE_INSTANCE_NAME: {
		url: `${CONSTANT.URL_PREFIX}instance`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	RENAME_INSTANCE: {
		url: `${CONSTANT.URL_PREFIX}instance/update-bulk`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	PICK_INSTANCE_AS_CONFIGURATION: {
		url: `${CONSTANT.URL_PREFIX}group/config/select/`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.PUT,
		use_mock: false,
		loading: true,
	},
	EXPORT_FILTER: {
		url: `${CONSTANT.URL_PREFIX}instance/export`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		responseType: 'blob',
		headers: {
			'Access-Control-Expose-Headers': 'Content-Disposition',
		},
		observe: 'response',
	},
	DEACTIVATE_LICENSE: {
		url: `${CONSTANT.URL_PREFIX}instance/v1/deactivate`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	ACKNOWLEDGE_INSTANCES: {
		url: `${CONSTANT.URL_PREFIX}commands`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
	ACKNOWLEDGE_LIST: {
		url: `${CONSTANT.URL_PREFIX}commands/instances`,
		mock: null,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
		headers: {},
	},
};
/* eslint-enable */
