import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANT, URL_CONSTANT } from '@app/shared/constants';
import { IUserCreds } from '@app/shared/models';
import { clearLocalStorage, rewriteredirectParam } from '@app/shared/utils';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CmCookiesService } from '../cm-cookies/cm-cookies.service';
import { RequestService } from '../request.service';
import { AUTH_API } from './auth.api';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	constructor(
		private readonly requestService: RequestService,
		private readonly router: Router,
		private readonly cmCookiesService: CmCookiesService
	) {}

	public logout() {
		return this.requestService.action(AUTH_API.logout);
	}

	public refreshToken(): any {
		return this.requestService.action(AUTH_API.refresh_token);
	}

	public localLogin(userCreds: IUserCreds): Observable<any> {
		return this.requestService.action(AUTH_API.local_login, userCreds);
	}

	public expiredSession(): any {
		return this.requestService.action(AUTH_API.expired_session);
	}

	public isSetupWizard() {
		return this.requestService.action(AUTH_API.isSetupWizard);
	}

	/**
	 * Called when token invalid
	 * return to input credentials page to login
	 */
	public terminateSession() {
		this.redirectToLoginPage('', '');
	}

	/**
	 * Redirect to IDP login page set location to idp logout url
	 * Passing OCM previous visited route to IDP
	 */
	public redirectToLoginPage(redirect: string, ref: string | null) {
		if (environment.onCloud) {
			// Leave clear storage to my opswat if we bind with them
			if (!environment.myOpswatAuthen) {
				this.clearClientStorage();
			}
			const idpLogOutURL = redirect ? redirect : environment.ssoLogoutUrl;
			const newIdpLogOutURL = ref ? rewriteredirectParam(idpLogOutURL, 'redirect', ref) : idpLogOutURL;
			window.location.href = newIdpLogOutURL;
		} else {
			this.clearClientStorage();
			ref
				? this.router.navigate([URL_CONSTANT.LOGIN], { queryParams: { redirection: `${ref}` } })
				: this.router.navigate([URL_CONSTANT.LOGIN]);
		}
	}

	/**
	 * Redirect to IDP login url in order to acquire new token
	 * Passing OCM previous visited route to IDP
	 */
	public acquireTokenFromIdp(ref: string | null) {
		if (environment.onCloud) {
			window.location.href = ref ? `${environment.ssoLoginUrl}?redirection=${ref}` : environment.ssoLoginUrl;
		} else {
			ref
				? this.router.navigate([URL_CONSTANT.LOGIN], { queryParams: { redirection: `${ref}` } })
				: this.router.navigate([URL_CONSTANT.LOGIN]);
		}
	}

	public clearClientStorage() {
		clearLocalStorage([CONSTANT.COOKIE_KEY]);
		this.cmCookiesService.deleteIDPCookies();
	}
}
