import { CONSTANT, TOKEN_USE } from '@app/shared/constants';
import { MOCK } from './mock';
import { INCIDENT_DETAIL, INCIDENT_OVERVIEW } from './inventory-incidient.mock';

/* eslint-disable */
export const INVENTORY_OVERVIEW = {
	GET_INVENTORY_STATISTIC: {
		url: `${CONSTANT.URL_PREFIX}dashboard/inventory-status`,
		mock: MOCK,
		method: CONSTANT.HTTP_METHOD.GET,
		use_mock: false,
		loading: true,
		customContext: TOKEN_USE.OCM,
	},
	GET_INVENTORY_INCIDENT: {
		url: `${CONSTANT.URL_PREFIX}dashboard/incident-overview`,
		mock: INCIDENT_OVERVIEW,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
	GET_INVENTORY_INCIDENT_DETAIL: {
		url: `${CONSTANT.URL_PREFIX}dashboard/incident-details`,
		mock: INCIDENT_DETAIL,
		method: CONSTANT.HTTP_METHOD.POST,
		use_mock: false,
		loading: true,
	},
};
