import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OPSWAT_PRODUCTS, URL_CONSTANT } from '@app/shared/constants';
import { AccessControlService } from '@app/shared/services/access-control/access-control.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';
@Injectable({
	providedIn: 'root',
})
export class OverviewGuard {
	constructor(private readonly accessControlService: AccessControlService, private readonly router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return new Observable<boolean | UrlTree>((observer) => {
			return this.accessControlService
				.getInventoryInfo()
				.pipe(take(1))
				.subscribe(() => {
					if (this.accessControlService.allProductKioskHaveLicense()) {
						return observer.next(true);
					} else if (this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.MK5.KEY)) {
						return observer.next(
							this.router.parseUrl(
								`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY[OPSWAT_PRODUCTS.MK5.TYPE].INSTANCE_LIST}`
							)
						);
					} else if (this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.KIOSK.KEY)) {
						return observer.next(
							this.router.parseUrl(
								`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY[OPSWAT_PRODUCTS.KIOSK.TYPE].INSTANCE_LIST}`
							)
						);
					} else {
						return observer.next(this.router.parseUrl(URL_CONSTANT.NOT_FOUND));
					}
				});
		});
	}
}
