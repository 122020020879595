import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { PermissionDeniedDialogComponent } from '@app/shared/components/permission-denied-dialog/permission-denied-dialog.component';
import { UserRemovedDialogComponent } from '@app/shared/components/user-removed-dialog/user-removed-dialog.component';
import {
	CONSTANT,
	DIALOG_HEIGHT,
	DIALOG_WIDTH,
	URL_CONSTANT,
	TOKEN_SCHEME,
	HTTP_ERROR,
	TOKEN_USE,
	REMOVED_USER_ERROR,
} from '@app/shared/constants';
import { AuthService, CmJwtService, ToastService } from '@app/shared/services';
import { PermissionDeniedDialogService } from '@app/shared/services/permission-denied-dialog/permission-denied-dialog.service';
import { showHttpErrorMsg } from '@app/shared/utils';
import { EMPTY, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
	lougoutEndpoint = 'auth/logout';
	refreshEndpoint = 'token/refresh';
	notificationEndpoint = 'notifications/incoming';
	errorCode = HTTP_ERROR;

	constructor(
		public router: Router,
		readonly cmJwtService: CmJwtService,
		readonly authService: AuthService,
		readonly dialog: MatDialog,
		public toastService: ToastService,
		private readonly permissionDeniedDialogService: PermissionDeniedDialogService
	) {}
	intercept(req: HttpRequest<any>, next: HttpHandler): any {
		const token = this.cmJwtService.getJwtToken();
		const authReq = this.addTokenHeader(req, token);

		return next.handle(authReq).pipe(
			catchError((error: any) => {
				if (error instanceof HttpErrorResponse) {
					switch (error.status) {
						case this.errorCode.SERVER_DOWN_502:
						case this.errorCode.SERVER_DOWN_503:
							showHttpErrorMsg(this.toastService, error.status, '', error.error);
							return error.statusText;
						case this.errorCode.DEPLOYING:
							break;
						case this.errorCode.BAD_REQUEST:
							return throwError(error);
						case this.errorCode.INTERNAL_SERVER:
							break;
						case this.errorCode.ACCESS_DENIED:
							this.handleShowPermissionDeniedDialog();
							if (req.url.includes(this.lougoutEndpoint)) {
								return throwError(error);
							}
							return throwError({ error: null, accessDeniedError: true });
						case this.errorCode.UNAUTHORIZED:
							return this.handleUnauthozied(req, error);
						default:
							return throwError(error);
					}
				}
				return throwError(error);
			})
		) as any;
	}

	private addTokenHeader(request: HttpRequest<any>, token = '') {
		const dxToken = this.cmJwtService.getDXToken();
		const customContext = request.context.get(TOKEN_SCHEME);
		let newRequest = request;
		if (!customContext) {
			newRequest = newRequest.clone({
				setHeaders: {
					Authorization: 'Bearer ' + token, // eslint-disable-line
				},
			});
		} else {
			switch (customContext) {
				case TOKEN_USE.NONE:
					break;
				case TOKEN_USE.OCM:
					if (token) {
						newRequest = newRequest.clone({
							setHeaders: {
								Authorization: 'Bearer ' + token, // eslint-disable-line
							},
						});
					}
					break;
				case TOKEN_USE.DX:
					if (dxToken) {
						newRequest = newRequest.clone({
							setHeaders: {
								Authorization: 'Bearer ' + dxToken, // eslint-disable-line
							},
						});
					}
					break;
				case TOKEN_USE.BOTH:
					if (token) {
						newRequest = newRequest.clone({
							setHeaders: {
								Authorization: 'Bearer ' + token, // eslint-disable-line
							},
						});
					}
					if (dxToken) {
						newRequest = newRequest.clone({
							setHeaders: {
								Myopswatjwt: dxToken, // eslint-disable-line
							},
						});
					}
					break;
				default:
					break;
			}
		}
		if (newRequest.url.includes('graphql')) {
			if (dxToken) {
				newRequest = newRequest.clone({
					setHeaders: {
						Authorization: 'Bearer ' + dxToken, // eslint-disable-line
					},
				});
			}
		}

		return newRequest;
	}

	private handleUnauthozied(req: HttpRequest<any>, error: HttpErrorResponse) {
		this.handleCloseAllDialogBeforeRedirect();
		if (error.error?.reason === REMOVED_USER_ERROR.ACCOUNT || error.error?.reason === REMOVED_USER_ERROR.SSO) {
			this.handleRemoveAccount(error);
			return of(EMPTY);
		}
		if (req.url.includes(this.refreshEndpoint)) {
			if (this.getOverlapRefreshRequest() > 1) {
				return of(EMPTY);
			}
		}
		// exclude error authen by notification
		if (req.url.includes(this.notificationEndpoint)) {
			console.log(`${new Date()}: exclude notification failure attemps`);
			return throwError(error);
		}

		// if logout is call, no need to navigate to redirect
		if (req.url.includes(this.lougoutEndpoint)) {
			return throwError(error);
		}

		if (req.url.includes('/api/console/auth/processLogin')) {
			return throwError(error);
		}

		return this.router.navigate([URL_CONSTANT.REDIRECT, { outlets: { secondary: null } }]);
	}

	private getOverlapRefreshRequest() {
		const refreshTokenTimestamps = localStorage
			.getItem(CONSTANT.AUTH.JWT_TIME)
			?.split(',')
			.map((item) => Number(item));
		const recentCount = refreshTokenTimestamps?.filter((timestamps: any) => timestamps > Date.now() - 1000).length || 1;
		console.log(`${new Date()}: multiple refresh call within a short time ${refreshTokenTimestamps} `);
		return recentCount;
	}

	private handleRemoveAccount(error: HttpErrorResponse) {
		localStorage.setItem('required_logout', 'true');

		if (!this.dialog.openDialogs || !this.dialog.openDialogs.length) {
			this.dialog.open(UserRemovedDialogComponent, {
				height: DIALOG_HEIGHT.SMALL,
				width: DIALOG_WIDTH.SMALL,
				data: {
					accountName: error.error.account,
					reason: error.error.reason,
				},
				disableClose: true,
			});
		}
	}

	private handleShowPermissionDeniedDialog() {
		const ref = this.dialog.openDialogs.filter((dialogRef) => dialogRef.componentInstance instanceof PermissionDeniedDialogComponent);
		if (ref.length === 0) {
			this.permissionDeniedDialogService.permissionDeniedDialog = this.dialog.open(PermissionDeniedDialogComponent, {
				height: DIALOG_HEIGHT.SMALL,
				width: DIALOG_WIDTH.SMALL,
				disableClose: true,
				autoFocus: false,
				id: 'permission-denied',
				data: { permissionDeniedMessage: '' },
			});
		}
	}

	private handleCloseAllDialogBeforeRedirect() {
		const refs = this.dialog.openDialogs;
		refs.forEach((ref) => ref.close());
	}
}
