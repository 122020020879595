export const SERVERS_SETTING = {
	'@class': 'ServerSettings',
	id: '657141eaaa477974e65a2a77',
	accountId: 'd568b52bd04e3d6b8d7f919a716672a0',
	type: 'SERVER',
	lastUpdated: 1655141925903,
	https: {
		enabled: true,
		certId: '6577f951d7ae71162bc10057',
		protocols: ['test'],
		port: 443,
		protocolsAsString: 'test',
	},
	smtp: {
		enabled: true,
		senderEmail: 'test@opswat.com',
		senderName: 'test',
		server: 'server',
		port: 0,
		usename: 'usename',
		connectionType: 'START_TLS',
		authMode: 'CRAM_MD5',
	},
	proxy: {
		enabled: false,
		systemProxy: true,
		sameSettings: true,
		bypassProxyServers: ['port'],
		bypassProxyEnabled: true,
	},
};

export const SERVERS_SETTING_FRESH = {
	'@class': 'ServerSettings',
	id: '65864bf17d2a6765ec55c5bb',
	accountId: '384f8f5b973f8518a1f70fd40d26b310',
	type: 'SERVER',
	lastUpdated: 1703300081461,
	https: {
		enabled: false,
		protocolsAsString: '',
	},
	smtp: {
		enabled: false,
	},
	proxy: {
		enabled: false,
		systemProxy: false,
		sameSettings: false,
		bypassProxyEnabled: false,
	},
};
export const REPORTS_MOCK = {
	productType: 'OCM',
	updateType: 'KIOSK_MK5_SUMMARY_REPORT',
	mailingList: [''],
	instanceIds: [],
	recurrencePattern: 'daily',
	spec: {
		type: 'PERIODIC',
		startAtHourInUtc0: 0,
		intervalSeconds: 60,
		cron: {
			seconds: {
				expression: 'ON',
				values: [0],
				specialChar: 'NONE',
			},
			minutes: {
				expression: 'EVERY',
				values: [1],
				specialChar: 'NONE',
			},
			hours: {
				expression: 'ALWAYS',
				values: [],
				specialChar: 'NONE',
			},
			dayOfMonth: {
				expression: 'ALWAYS',
				values: [],
				specialChar: 'NONE',
			},
			month: {
				expression: 'ALWAYS',
				values: [],
				specialChar: 'NONE',
			},
			dayOfWeek: {
				expression: 'ALWAYS',
				values: [],
				specialChar: 'NONE',
			},
		},
	},
};

export const INSTANCE_LIST_MOCK = [
	{
		id: '1',
		name: '1',
		groupId: '1',
	},
	{
		id: '2',
		name: '2',
		groupId: '2',
	},
];
