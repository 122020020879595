import { Injectable } from '@angular/core';
import { RequestService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { DX_PROFILE, ORG_LIST, UPDATE_ORGANIZATION, USER_PROFILE } from './user-profile.api';
import { IGeneralTableCriteria } from '@app/shared/models';
import { Apollo } from 'apollo-angular';
import { MutationOptions } from 'apollo-angular/types';
import { IUserProfile, IUserProfileResponse } from '../model/user-profile.model';
import { IAccount } from '../../settings/model/account.model';

@Injectable({
	providedIn: 'root',
})
export class UserProfileService {
	constructor(private readonly requestService: RequestService, private readonly apollo: Apollo) {}

	getUserProfile(): Observable<IUserProfileResponse> {
		return this.requestService.action(USER_PROFILE.getUserProfile);
	}

	saveUserProfile(userProfile: IUserProfile, hashedPin = '') {
		USER_PROFILE.saveUserProfile.headers = hashedPin ? { pin: hashedPin } : {};
		return this.requestService.action(USER_PROFILE.saveUserProfile, userProfile);
	}

	public getAccountList(params: IGeneralTableCriteria): Observable<IAccount> {
		return this.requestService.action(USER_PROFILE.getAccountList, params);
	}

	public switchAccount(params: any): Observable<any> {
		const api = JSON.parse(JSON.stringify(USER_PROFILE.switchAccount));
		api.params = params;
		return this.requestService.action(api);
	}

	public switchDxAccount(params: any): Observable<any> {
		const option: MutationOptions = { mutation: UPDATE_ORGANIZATION, variables: params, errorPolicy: 'all' };

		return this.apollo.mutate(option);
	}

	public getDxAccountList(): Observable<any> {
		return this.apollo.query({ query: ORG_LIST, errorPolicy: 'all' });
	}

	public getDxProfile(): Observable<any> {
		return this.apollo.query({ query: DX_PROFILE, errorPolicy: 'all' });
	}
}
