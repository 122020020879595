export const PAGE_SIZE_OPTIONS = [5, 10, 25, 50];
export const DEFAULT_LIMIT_ITEMS = 10;
export const DEFAULT_LIMIT_ROLE = 100;
export const DEFAULT_PAGE = 0;

export enum DIRECTION {
	ASC = 'asc',
	DESC = 'desc',
}

export const DEFAULT_SORT_VALUE = {
	KIOSK_OVERVIEW_INSTANCE: 'license',
	KIOSK_INSTANCE: 'lastSeen',
	KIOSK_REPORT: 'startTime',
	PRODUCT_INSTANCE: 'lastSeen',
	KIOSK_GROUP: 'lowerName',
	PRODUCT_GROUP: 'lowerName',
	KIOSK_GROUP_DETAIL_INSTANCE: 'lastSeen',
	EVENT_AND_HISTORY: 'timestamp',
	MEDIA_FIREWALL_GROUP: 'lastSeen',
	MEDIA_FIREWALL_REPORT: 'startTime',
	CERTIFICATE_SETTING: 'validTo',
	START_TIME: 'startTime',
	ACKNOWLEDGE_TIME: 'createdAt',
};

export const DISPLAYED_COLUMNS = {
	KIOSK_OVERVIEW_INSTANCE: [
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'ip',
		'group',
		'productType',
		'lastSeen',
		'lastReported',
	],
	FIREWALL_INSTANCE: ['select', 'lowerName', 'healthStatus', 'status', 'version', 'ip', 'group', 'lastSeen', 'lastReported'],
	KIOSK_INSTANCE: [
		'select',
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'ip',
		'group',
		'lastSeen',
		'lastReported',
	],
	CORE_INSTANCE: [
		'select',
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'platform',
		'ip',
		'group',
		'lastSeen',
		'lastReported',
	],
	CORE_HISTORY: ['fileName', 'status', 'result', 'workflow', 'startTime', 'durationSeconds', 'hash', 'user', 'instanceName', 'groupName'],
	KIOSK_GROUP_DETAIL_INSTANCE: [
		'select',
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'ip',
		'lastSeen',
		'lastReported',
		'action',
	],
	FIREWALL_DETAIL_INSTANCE: ['select', 'lowerName', 'healthStatus', 'status', 'version', 'ip', 'lastSeen', 'lastReported', 'action'],
	KIOSK_LINUX_REPORT: [
		'select',
		'lowerName',
		'totalFiles',
		'blockedFile',
		'infectedFile',
		'vulnerableFile',
		'cdrFile',
		'dlpFile',
		'startTime',
	],
	MEDIA_FIREWALL_REPORT: ['select', 'lowerName', 'totalFiles', 'allowedFile', 'blockedFile', 'concealedFile', 'startTime'],
	KIOSK_GROUP: [
		'select',
		'lowerName',
		'instancesCount',
		'disconnectedCount',
		'productVersion',
		'settingsVersion',
		'unlicensedCount',
		'lastUpdated',
	],

	CORE_GROUP: [
		'select',
		'lowerName',
		'instancesCount',
		'disconnectedCount',
		'productVersion',
		'settingsVersion',
		'unlicensedCount',
		'lastUpdated',
	],

	PRODUCT_GROUP_DETAIL_INSTANCE: ['select', 'lowerName', 'healthStatus', 'status', 'license', 'version', 'ip', 'lastSeen'],
	PRODUCT_GROUP_DETAIL_INSTANCE_LEVEL1: [
		'select',
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'ip',
		'lastSeen',
		'lastReported',
		'action',
	],
	PRODUCT_INSTANCE: ['select', 'lowerName', 'healthStatus', 'status', 'license', 'version', 'ip', 'group', 'lastSeen'],

	PRODUCT_INSTANCE_LEVEL1: [
		'select',
		'lowerName',
		'healthStatus',
		'status',
		'license',
		'version',
		'configVersion',
		'ip',
		'group',
		'lastSeen',
		'lastReported',
	],
	PRODUCT_GROUP: ['select', 'lowerName', 'instancesCount', 'disconnectedCount', 'unlicensedCount', 'lastUpdated'],
	MEDIA_FIREWALL_GROUP: [
		'select',
		'lowerName',
		'instancesCount',
		'disconnectedCount',
		//'productVersion',
		'lastUpdated',
	],
	PRODUCT_GROUP_LEVEL1: [
		'select',
		'lowerName',
		'instancesCount',
		'disconnectedCount',
		'productVersion',
		'settingsVersion',
		'unlicensedCount',
		'lastUpdated',
	],
	EVENT_AND_HISTORY: ['timestamp', 'time', 'user', 'action', 'impacted', 'description', 'newValue', 'actions'],
	PACKAGE: ['packageName', 'timestamp', 'hash', 'action'],
	CERTIFICATE_SETTING: ['name', 'status', 'issuedBy', 'issuedTo', 'description', 'validTo', 'actions'],
};

export const KEY_OF_NAME_VALUE = {
	REPORT_NAME: 'name',
};
