import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { OPSWAT_PRODUCTS, URL_CONSTANT } from '@app/shared/constants';
import { AccessControlService } from '@app/shared/services/access-control/access-control.service';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
@Injectable({
	providedIn: 'root',
})
export class DashboardGuard {
	constructor(private readonly accessControlService: AccessControlService, private readonly router: Router) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
		return new Observable<boolean | UrlTree>((observer) => {
			return this.accessControlService.getInventoryInfo().pipe(take(1)).subscribe(() => {
				if (this.accessControlService.allProductKioskHaveLicense()) {
					const stateUrl = state.url;
					const currentUrl = new URL(stateUrl, 'http://localhost:4200');
					if (
						currentUrl.pathname &&
						(currentUrl.pathname ===
							`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.MK5.INSTANCE_LIST}${URL_CONSTANT.INVENTORY.DASHBOARD}` ||
							currentUrl.pathname ===
							`${URL_CONSTANT.INVENTORY.INVENTORY}${URL_CONSTANT.INVENTORY.KIOSK.INSTANCE_LIST}${URL_CONSTANT.INVENTORY.DASHBOARD}`)
					) {
						const newUrl = currentUrl.pathname.replace('dashboard', 'instances');
						return observer.next(this.router.parseUrl(newUrl));
					}
					return observer.next(true);
				} else if (
					this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.MK5.KEY) ||
					this.accessControlService.productHasLicense(OPSWAT_PRODUCTS.KIOSK.KEY)
				) {
					return observer.next(true);
				} else {
					return observer.next(this.router.parseUrl(URL_CONSTANT.NOT_FOUND));
				}
			});
		});
	}
}
