export enum IncidentTypes {
	THREAT = 'THREAT',
	SENSITIVE_DATA = 'SENSITIVE_DATA',
	SUSPICIOUS = 'SUSPICIOUS',
	POTENTIALLY_VULNERABLE = 'POTENTIALLY_VULNERABLE',
	OTHERS = 'OTHERS',
}

export const SECURITY_INCIDENT = {
	[IncidentTypes.THREAT]: 'Threats',
	[IncidentTypes.SENSITIVE_DATA]: 'Sensitive Data',
	[IncidentTypes.SUSPICIOUS]: 'Suspicious Files',
	[IncidentTypes.POTENTIALLY_VULNERABLE]: 'Potential Vulnerabilities',
	[IncidentTypes.OTHERS]: 'Others',
};

export const INCIDENT_FROM_KEY = {
	threat: IncidentTypes.THREAT,
	sensitiveData: IncidentTypes.SENSITIVE_DATA,
	suspicious: IncidentTypes.SUSPICIOUS,
	potentiallyVulnerable: IncidentTypes.POTENTIALLY_VULNERABLE,
	others: IncidentTypes.OTHERS,
};

// TODO: revise wording
export enum IncidentSeverity {
	CRITICAL = 'critical',
	HIGH = 'high',
	MEDIUM = 'medium',
	NONE = 'none',
	INFORMATION = 'info',
}

// for compare
export const SEVERITY_LEVEL_MAPING = {
	[IncidentSeverity.CRITICAL]: 4,
	[IncidentSeverity.HIGH]: 3,
	[IncidentSeverity.MEDIUM]: 2,
	[IncidentSeverity.INFORMATION]: 1,
	[IncidentSeverity.NONE]: 0,
};
export const SEVERITY_SETTING = [
	{ issue: IncidentTypes.THREAT, severity: IncidentSeverity.CRITICAL, showNotification: true },
	{ issue: IncidentTypes.SENSITIVE_DATA, severity: IncidentSeverity.HIGH, showNotification: true },
	{ issue: IncidentTypes.SUSPICIOUS, severity: IncidentSeverity.MEDIUM, showNotification: true },
	{ issue: IncidentTypes.POTENTIALLY_VULNERABLE, severity: IncidentSeverity.MEDIUM, showNotification: true },
];

export const INCIDENT_DISPLAY_SEVERITY = {
	[IncidentSeverity.CRITICAL]: 'negative',
	[IncidentSeverity.HIGH]: 'slightly-negative',
	[IncidentSeverity.MEDIUM]: 'cautionary',
	[IncidentSeverity.NONE]: 'neutral',
	[IncidentSeverity.INFORMATION]: 'positive',
};
