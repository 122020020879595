import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CONSTANT, OPSWAT_PRODUCTS } from '@app/shared/constants';
import { ToastService } from '@app/shared/services';
import { InventoryOverviewService } from '../../services/inventory/inventory-overview.service';
import { showHttpErrorMsg } from '@app/shared/utils';
import { IIncidentDetail, IIncidentDetailItem } from '../../models/incident.model';
import { IncidentTypes, SECURITY_INCIDENT } from '../../constants/incident.constant';

@Component({
	selector: 'app-incident-detail-dialog',
	templateUrl: './incident-detail-dialog.component.html',
	styleUrls: ['./incident-detail-dialog.component.scss'],
})
export class IncidentDetailDialogComponent implements OnInit {
	threatsColumn = ['filePath', 'instanceName', 'user', 'startTime', 'mediaType'];
	othersColumn = ['filePath', 'instanceName', 'user', 'startTime', 'mediaType'];
	columnsToDisplay = this.threatsColumn;
	total: any;
	issueName = '';
	productName = '';
	isLoading = false;
	searchCriteria: any = {
		endTime: 0,
		amount: 0,
		unit: 0,
		utcOffset: 0,
		groupsByProductType: {},
		filters: {},
		page: 0,
		limit: CONSTANT.MAXIMUM_PAGE_RECORD,
	};
	incidents: IIncidentDetailItem[] = [];

	constructor(
		public dialogRef: MatDialogRef<IncidentDetailDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private readonly toastService: ToastService,
		private readonly inventoryOverviewService: InventoryOverviewService
	) {}

	ngOnInit() {
		this.buildsearchCriteria();
		this.productName = OPSWAT_PRODUCTS[this.data.productType].FULL_NAME;
		this.issueName = SECURITY_INCIDENT[this.data.issue];
		if (this.data.issue !== IncidentTypes.THREAT) {
			this.columnsToDisplay = this.othersColumn;
		}
		this.isLoading = true;
		this.inventoryOverviewService.getIncidentDetail(this.searchCriteria).subscribe(
			(data: IIncidentDetail) => {
				this.total = data.total;
				this.incidents = data.rows;
				this.isLoading = false;
			},
			({ error, status }) => {
				this.isLoading = false;
				if (error) {
					showHttpErrorMsg(this.toastService, status, 'Get Incident Overview', error.error);
				}
			}
		);
	}
	onCancel() {
		this.dialogRef.close();
	}

	buildsearchCriteria() {
		this.searchCriteria.endTime = this.data.filter.endTime;
		this.searchCriteria.amount = this.data.filter.amount;
		this.searchCriteria.unit = this.data.filter.unit;
		this.searchCriteria.utcOffset = this.data.filter.utcOffset;
		this.searchCriteria.groupsByProductType = [
			{
				productType: this.data.productType,
				groupIds: [],
			},
		];
		this.searchCriteria.filters.issue = [this.data.issue];
	}

	onTableScroll(e: any) {
		const tableViewHeight = e.target.offsetHeight; // viewport
		const tableScrollHeight = e.target.scrollHeight; // length of all table
		const scrollLocation = e.target.scrollTop; // how far user scrolled

		// If the user has scrolled within 100px of the bottom, add more data
		const buffer = 100;
		const limit = tableScrollHeight - tableViewHeight - buffer;
		if (scrollLocation > limit && this.incidents.length < this.total) {
			this.searchCriteria.page++;
			this.getIncidentDetail();
		}
	}

	getIncidentDetail() {
		this.inventoryOverviewService.getIncidentDetail(this.searchCriteria).subscribe(
			(data: IIncidentDetail) => {
				this.incidents = this.incidents.concat(data.rows);
			},
			({ error, status }) => {
				if (error) {
					showHttpErrorMsg(this.toastService, status, 'Get Incident detail', error.error);
				}
			}
		);
	}
}
