import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
	selector: 'app-permission-denied-dialog',
	templateUrl: './permission-denied-dialog.component.html',
})
export class PermissionDeniedDialogComponent {
	constructor(public dialogRef: MatDialogRef<PermissionDeniedDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

	onCancel() {
		this.dialogRef.close(false);
	}
}
