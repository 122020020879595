import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PERMISSION, PERMISSION_KEY } from '@app/shared/constants';
import { RbacService, ToastService } from '@app/shared/services';
import { NotificationService } from '@app/shared/services/notification/notification.service';
import { PublisherService } from '@app/shared/services/publisher/publisher.service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-incident-alert-pannel',
	templateUrl: './incident-alert-pannel.component.html',
	styleUrls: ['./incident-alert-pannel.component.scss'],
})
export class IncidentAlertPannelComponent implements OnInit, OnDestroy {
	data: any = [];
	subscription!: Subscription;
	PERMISSION = PERMISSION;
	permission = PERMISSION.NONE;
	constructor(
		public dialogRef: MatDialogRef<IncidentAlertPannelComponent>,
		private readonly publisherService: PublisherService,
		private readonly toastService: ToastService,
		private readonly notificationService: NotificationService,
		private readonly rbacService: RbacService,
	) {}
	ngOnInit(): void {
		this.subscription = this.publisherService.follow('securityIncidents').subscribe((data) => (this.data = data));
		this.permission = this.rbacService.getPermission(PERMISSION_KEY.inventory);
	}
	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	onCancel() {
		this.dialogRef.close();
	}

	dismissAll() {
		const allItemsID = this.data.map((item: any) => item.id);
		this.notificationService.checkMessage(allItemsID).subscribe(() => {
			this.data = [];
			this.publisherService.publish('updateSecurityIncident', true);
		});
	}
	onCloseNotification(id: string) {
		const index = this.data.findIndex((item: any) => item?.id === id);
		if (index !== -1) {
			this.data.splice(index, 1);
		}
		this.notificationService.checkMessage([id]).subscribe(() => {});
	}
}
